import { components } from 'react-select';

import { Dropdown, InputField } from '@/components/atomic/nuclei';
import phoneCountryCodes from '@/config/phoneCountryCodes';

const CustomOption = ({ children, data: { name, code }, ...props }) => (
  <components.Option {...props}>
    {code} {name}
  </components.Option>
);

const PhoneCodeSelectFormInput = ({
  className,
  clearErrors,
  control,
  dbName,
  errors,
  getValues,
  inputFieldProps,
  register,
  selectContainerClassName,
  setValue,
  trigger,
  width
}) => (
  <>
    <Dropdown
      {...{
        className,
        clearErrors,
        components: { Option: CustomOption },
        containerClass: selectContainerClassName,
        control,
        dbName,
        defaultValue: getValues(dbName),
        errors,
        getOptionLabel: ({ code }) => code,
        getOptionValue: ({ code, name }) => `${code}${name}`,
        getValues,
        isSearchable: true,
        name: 'CodeId',
        onBlur: () => {
          trigger(inputFieldProps.dbName);
        },
        onChange: (e) => {
          setValue(dbName, e);
        },
        options: phoneCountryCodes,
        register,
        setValue,
        width
      }}
    />
    <InputField {...inputFieldProps} />
  </>
);

export default PhoneCodeSelectFormInput;
