import { GoogleApiWrapper } from 'google-maps-react';
import Image from 'next/image';
import { useState } from 'react';
import Geocode from 'react-geocode';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

import { Button, CloseIcon, Text } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import getCurrentGeolocation from '@/lib/getCurrentGeolocation';

const publicGoogleMapApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

const getCityId = ({ cityList, city }) =>
  city && cityList.find(({ name }) => name === city)?.slug;

const BottomModalHeader = ({ label }) => (
  <div className='p-3 md:p-6 md:pb-0 px-0 md:px-0'>
    <Text
      {...{
        className:
          'font-medium font-Montserrat leading-5 text-sm md:text-base text-nero text-center',
        content: label
      }}
    />
  </div>
);

const getCurrentLocation = async ({
  addressDetail,
  cityList,
  setAddressDetail
}) => {
  getCurrentGeolocation().then(({ latitude, longitude }) => {
    setAddressDetail(() => ({ ...addressDetail, latitude, longitude }));
    Geocode.fromLatLng(latitude, longitude).then(
      ({
        results: [
          {
            address_components: addressComponentsFromGeoCode,
            formatted_address: formattedAddress
          }
        ] = [{}]
      }) => {
        const addressComponents = addressComponentsFromGeoCode;
        const { long_name: city } = addressComponents.find((component) =>
          component.types.includes('locality')
        );
        setAddressDetail(() => ({
          ...addressDetail,
          cityId: getCityId({ cityList, city }),
          formattedAddress,
          locationSelected: true
        }));
      },
      (err) => err
    );
  });
};

const saveAddress = async ({
  addAddressToggler,
  addressDetail,
  event,
  setError,
  setUserAddress
}) => {
  event.preventDefault();
  if (addressDetail.cityId) {
    setUserAddress({
      buildingName: addressDetail.buildingName,
      cityId: addressDetail.cityId,
      formattedAddress: addressDetail.formattedAddress,
      houseNumber: addressDetail.houseNumber,
      landmark: addressDetail.landmark,
      latitude: addressDetail.latitude,
      locationSelected: addressDetail.locationSelected,
      longitude: addressDetail.longitude
    });
    addAddressToggler();
  } else {
    setError('This field is mandatory');
  }
};

const changeAddress = ({ setAddressDetail, addressDetail }) => {
  setAddressDetail(() => ({
    ...addressDetail,
    buildingName: '',
    cityId: '',
    formattedAddress: '',
    houseNumber: '',
    landmark: '',
    locationSelected: false
  }));
};

const handleSelect = async ({
  addressDetail,
  cityList,
  setAddressDetail,
  value
}) => {
  const [results] = await geocodeByAddress(value);
  const addressComponents = results.address_components;
  const { long_name: city } = addressComponents.find((component) =>
    component.types.includes('locality')
  );
  const latLng = await getLatLng(results);
  setAddressDetail(() => ({
    ...addressDetail,
    cityId: getCityId({ cityList, city }),
    formattedAddress: value,
    latitude: latLng.lat,
    locationSelected: true,
    longitude: latLng.lng
  }));
};

const PlacesSuggestion = ({ getSuggestionItemProps, suggestion }) => (
  <div
    {...getSuggestionItemProps(suggestion)}
    className='cursor-pointer'
    key={suggestion.placeId}
  >
    <Text
      {...{
        content: suggestion.description,
        HtmlTag: 'span'
      }}
    />
  </div>
);

const RenderPlacesComponent = ({
  getInputProps,
  getSuggestionItemProps,
  loading,
  suggestions
}) => (
  <div className='ms-3 w-full'>
    <input
      {...getInputProps({
        placeholder: 'Search for area, Street name',
        className:
          'appearance-none location-search-input w-full focus:outline-none h-12.5 cursor-pointer'
      })}
    />
    <div className='autocomplete-dropdown-container'>
      {loading && <Text {...{ content: 'Loading...' }} />}
      {suggestions.map((suggestion, index) => (
        <PlacesSuggestion
          key={index}
          {...{ getSuggestionItemProps, suggestion }}
        />
      ))}
    </div>
  </div>
);

const AddressDetailFormInput = ({
  autoComplete,
  id,
  label,
  onChange,
  placeholder,
  value
}) => (
  <div className='mt-4 w-full mx-auto'>
    <Text
      {...{
        content: label,
        HtmlTag: 'span',
        className: 'text-sm text-dim-gray mb-3 font-above-normal block'
      }}
    />
    <div className='flex'>
      <input
        {...{
          autoComplete,
          className: `appearance-none cursor-pointer hover:shadow-orderbox focus:outline-none h-12.5 w-full text-sm leading-5 font-medium text-nero placeholder-lightgray font-Montserrat shadow-category p-3 md:p-4 rounded-md`,
          id,
          onChange,
          placeholder,
          type: 'text',
          value
        }}
      />
    </div>
  </div>
);

const AutoFillAddress = ({
  addAddressToggler,
  addressDetail,
  setAddressDetail,
  cityList
}) => (
  <div className='px-5 md:px-6 py-3 md:py-0'>
    <CloseIcon
      {...{
        className: 'flex justify-end cursor-pointer relative top-2 left-3',
        iconClassName: 'cursor-pointer',
        onClick: addAddressToggler
      }}
    />
    <BottomModalHeader label='Search location' />
    <div className='mt-5 w-full mx-auto'>
      <div className='border-b border-gray-150 flex'>
        <Image
          alt='eva_search'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/search-outlet-gray.svg`}
          style={{ height: 20, width: 20 }}
          width={0}
        />
        <PlacesAutocomplete
          value={addressDetail.formattedAddress}
          onChange={(e) =>
            setAddressDetail(() => ({
              ...addressDetail,
              formattedAddress: e
            }))
          }
          onSelect={(value) =>
            handleSelect({
              value,
              setAddressDetail,
              addressDetail,
              cityList
            })
          }
        >
          {RenderPlacesComponent}
        </PlacesAutocomplete>
      </div>
      <div className='flex gap-1 mt-2'>
        <Image
          alt='current_location'
          height={0}
          src={`${staticMediaStoreBaseURL}/icons/current-location.svg`}
          style={{ height: 15, width: 15 }}
          width={0}
        />
        <Text
          {...{
            className:
              'text-cinnabar font-medium ms-2 cursor-pointer group-hover:text-error-red text-xs md:text-sm leading-5',
            content: 'Use current location',
            HtmlTag: 'span',
            onClick: () =>
              getCurrentLocation({
                addressDetail,
                cityList,
                setAddressDetail
              })
          }}
        />
      </div>
    </div>
  </div>
);

const AddressDetailForm = ({
  addAddressToggler,
  addressDetail,
  cityList,
  error,
  setAddressDetail,
  setError,
  setUserAddress
}) => (
  <form
    onSubmit={(event) =>
      saveAddress({
        addAddressToggler,
        addressDetail,
        event,
        setError,
        setUserAddress
      })
    }
    className='px-5 md:px-6 py-3 md:py-0'
    method='post'
  >
    <CloseIcon
      {...{
        className: 'flex justify-end cursor-pointer relative top-2 left-3',
        iconClassName: 'cursor-pointer',
        onClick: addAddressToggler
      }}
    />
    <BottomModalHeader label='Enter address details' />
    <div className='mt-4 w-full mx-auto'>
      <Text
        {...{
          content: ' Your Location',
          HtmlTag: 'span',
          className: 'text-sm text-dim-gray mb-3 font-above-normal block'
        }}
      />
      <div className='group flex shadow-category hover:shadow-orderbox p-3 md:p-4 rounded-md h-12.5 justify-start items-center'>
        <Text
          {...{
            content: addressDetail.formattedAddress,
            HtmlTag: 'span',
            id: 'yourlocation',
            className: `cursor-pointer truncate h-12.5 md:ms-1 w-10/12 md:w-full text-sm leading-5 font-medium text-nero placeholder-lightgray font-Montserrat flex justify-start items-center overflow-x-auto no-scrollbar`
          }}
        />
        <Text
          {...{
            content: 'change',
            HtmlTag: 'span',
            className:
              'text-cinnabar text-xs font-medium cursor-pointer md:text-sm leading-5 capitalize ms-1',
            onClick: () => changeAddress({ setAddressDetail, addressDetail })
          }}
        />
      </div>
    </div>

    <div className='mt-4 w-full mx-auto'>
      <div className='flex flex-col'>
        <label
          className='text-sm text-dim-gray mb-3 font-above-normal block'
          htmlFor='eventTypeId'
        >
          City
        </label>

        <div className='relative inline-flex w-full items-center'>
          <div className='w-3 h-3 absolute top-6 right-4 pointer-events-none'>
            <Image
              alt='down SlateGrey Arrow'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/down-slate-grey-arrow.svg`}
              style={{ height: 18, width: 18 }}
              width={0}
            />
          </div>
          <select
            className='cursor-pointer hover:shadow-orderbox w-full h-12.5 pr-10 p-3 md:p-4 bg-white focus:outline-none appearance-none
             text-sm leading-5 font-medium text-nero placeholder-lightgray font-Montserrat shadow-category rounded-md'
            name='eventTypeId'
            onChange={(e) => {
              setAddressDetail(() => ({
                ...addressDetail,
                cityId: e.target.value
              }));
              setError('');
            }}
            required
            value={addressDetail.cityId}
          >
            <option
              className='text-sm font-medium p-2 cursor-pointer'
              value=''
            >
              Choose City
            </option>
            {cityList.length &&
              cityList.map((option) => (
                <option
                  key={option.id}
                  className='text-sm font-medium p-2 cursor-pointer'
                  value={option.slug}
                >
                  {option.name}
                </option>
              ))}
          </select>
        </div>
        {error && (
          <Text
            {...{
              HtmlTag: 'span',
              className: 'slide-down ms-1.5 mt-2 text-cinnabar text-xs',
              content: error
            }}
          />
        )}
      </div>
    </div>

    <AddressDetailFormInput
      {...{
        label: 'House no / Flat no. (optional)',
        id: 'houseNumber',
        value: addressDetail.houseNumber,
        onChange: (e) => {
          setAddressDetail(() => ({
            ...addressDetail,
            houseNumber: e.target.value
          }));
        },
        placeholder: 'Enter House no / Flat no.',
        autoComplete: 'off'
      }}
    />

    <AddressDetailFormInput
      {...{
        label: 'Building / Premise Name (optional)',
        id: 'houseNumber',
        value: addressDetail.buildingName,
        onChange: (e) => {
          setAddressDetail(() => ({
            ...addressDetail,
            buildingName: e.target.value
          }));
        },
        placeholder: 'Enter Building / Premise Name',
        autoComplete: 'off'
      }}
    />

    <AddressDetailFormInput
      {...{
        id: 'houseNumber',
        label: 'Landmark (Optional)',
        value: addressDetail.landmark,
        onChange: (e) => {
          setAddressDetail(() => ({
            ...addressDetail,
            landmark: e.target.value
          }));
        },
        placeholder: 'Enter Landmark',
        autoComplete: 'off'
      }}
    />

    <div className='w-full md:mx-auto flex md:flex-row-reverse justify-center md:justify-start mt-8 mb-3 md:mb-7'>
      <Button
        {...{
          className:
            'bg-brand-gradient w-full text-white text-sm md:text-base font-medium h-12.5 py-4 rounded-md outline-none focus:outline-none',
          label: 'Save Address',
          onClick: (event) =>
            saveAddress({
              event,
              setUserAddress,
              addressDetail,
              addAddressToggler,
              setError
            })
        }}
      />
    </div>
  </form>
);

const AddAddress = ({
  addAddressToggler,
  cityList,
  setUserAddress,
  userAddress: {
    buildingName,
    cityId,
    formattedAddress,
    houseNumber,
    landmark,
    latitude,
    locationSelected,
    longitude
  }
}) => {
  Geocode.setApiKey(publicGoogleMapApiKey);

  const [addressDetail, setAddressDetail] = useState({
    buildingName,
    cityId,
    formattedAddress,
    houseNumber,
    landmark,
    latitude,
    locationSelected,
    longitude
  });
  const [error, setError] = useState('');

  return !addressDetail.locationSelected ? (
    <AutoFillAddress
      {...{
        addAddressToggler,
        addressDetail,
        setAddressDetail,
        cityList
      }}
    />
  ) : (
    <AddressDetailForm
      {...{
        addAddressToggler,
        addressDetail,
        cityList,
        error,
        setAddressDetail,
        setError,
        setUserAddress
      }}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: publicGoogleMapApiKey
})(AddAddress);
