import Image from 'next/image';

import { Avatar, Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { removeAuth } from '@/services/identity.service';
import { getMetabaseDashboardHomeURL } from '@/services/opsPortal.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';

const MenuHeader = ({ plannerName }) => (
  <div className='bg-sidebar-menu bg-no-repeat bg-cover rounded-2xl h-[8.5rem] p-4'>
    <Text
      {...{
        className: 'text-2xl font-bold text-white',
        content: 'Have an Eventful day 🎉'
      }}
    />
    <div className='flex items-center mt-1 gap-1'>
      <div className='bg-sidebar-avatar flex justify-center items-center bg-no-repeat w-[4.5rem] h-[4.2rem] text-[28px] font-extrabold'>
        <Avatar
          {...{
            badgeStyle: 'w-[60px] h-[60px]',
            name: plannerName,
            textStyle: 'bg-white text-brand-gradient text-2xl'
          }}
        />
      </div>
      <TextKeyValuePair
        {...{
          label: plannerName,
          labelClass: 'text-lg font-semibold text-white leading-4',
          spaceTop: '!gap-1',
          value: 'Event Planner',
          valueClassName: 'text-sm font-medium text-white'
        }}
      />
    </div>
  </div>
);

const MenuItem = ({ alt, className, hoverIcon, icon, label, onClick }) => (
  <div
    className={`flex flex-1 py-3 px-4 justify-start items-center gap-4 sidebar-menu rounded-lg ${
      className || 'hover:bg-brand-gradient'
    }`}
    onClick={onClick}
  >
    <Image
      alt={alt}
      className='menu-white-icon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${icon}`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    <Image
      alt={alt}
      className='hidden menu-red-icon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${hoverIcon}`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    <Text
      {...{
        className: 'text-nero text-sm md:text-base font-medium',
        content: label
      }}
    />
  </div>
);

const NavigationMenu = ({
  menuClass = 'top-[63px]',
  plannerName,
  router,
  setOpenAddHostModal,
  toggleMenu
}) => {
  const MENU_CONFIG = [
    {
      alt: 'quick find icon',
      className: '',
      hoverIcon: 'quick-find-white-icon.svg',
      icon: 'quick-find-icon.svg',
      label: 'Quick Nav',
      onClick: () =>
        router.push(getPageURL({ pageName: PAGE_NAME.NAVIGATION.label }))
    },
    {
      alt: 'search brand icon',
      className: '',
      hoverIcon: 'search-white-icon.svg',
      icon: 'search-brand-icon.svg',
      label: 'Product Search',
      onClick: () =>
        router.push(getPageURL({ pageName: PAGE_NAME.PRODUCT_SEARCH.label }))
    },
    {
      alt: 'order list icon',
      className: '',
      hoverIcon: 'order-white-icon.svg',
      icon: 'order-brand-icon.svg',
      label: 'Orders',
      onClick: () =>
        router.push(
          getPageURL({
            pageName: PAGE_NAME.ORDER_LIST.label,
            queryParams: {
              showMyUpcomingOrders: true
            }
          })
        )
    },
    {
      alt: 'add host icon',
      className: '',
      hoverIcon: 'host-white-v1-icon.svg',
      icon: 'host-brand-v1-icon.svg',
      label: 'Add Host',
      onClick: () => {
        toggleMenu();
        setOpenAddHostModal(true);
      }
    },
    {
      alt: 'dashboard icon',
      className: '',
      hoverIcon: 'menu-dashboard-icon.svg',
      icon: 'menu-dashboard-red-icon.svg',
      label: 'All Dashboards',
      onClick: () => router.push(getMetabaseDashboardHomeURL())
    }
  ];

  return (
    <div
      className={`fixed -right-0 h-screen overflow-hidden bg-white z-[100] py-2 px-2 ${menuClass}`}
    >
      <div className='relative'>
        <MenuHeader {...{ plannerName }} />
        <div className='flex flex-col text-nero w-[21.8rem] rounded mt-2'>
          {MENU_CONFIG.map(
            (
              { alt, className, hoverIcon, icon, label, onClick, show = true },
              index
            ) =>
              show && (
                <MenuItem
                  key={index}
                  {...{
                    alt,
                    className,
                    hoverIcon,
                    icon,
                    label,
                    onClick
                  }}
                />
              )
          )}
          <MenuItem
            {...{
              alt: 'logout red icon',
              className:
                'fixed bottom-2 flex flex-1 sidebar-logout-menu right-[7rem] !py-2',
              hoverIcon: 'logout-red.svg',
              icon: 'logout.svg',
              label: 'Logout',
              onClick: () => {
                removeAuth();
                router
                  .push(getPageURL({ pageName: PAGE_NAME.WELCOME.label }))
                  .then(() => router.reload());
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NavigationMenu;
