import { useEffect, useRef } from 'react';
import { useInfiniteHits } from 'react-instantsearch';

const InstantSearchInfiniteScroll = ({
  hitComponent: HitComponent,
  ...props
}) => {
  const { items, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isLastPage) {
            showMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
    return () => {};
  }, [isLastPage, showMore]);

  return (
    <div className='ais-InfiniteHits flex flex-1 mt-[70px] md:mt-0'>
      <ul className='ais-InfiniteHits-list flex flex-col gap-4 w-full'>
        {items.map((hit) => (
          <li
            className='ais-InfiniteHits-item hover:text-nero !border-none !p-0 !rounded-none shadow-none'
            key={hit.objectID}
          >
            <HitComponent hit={hit} />
          </li>
        ))}
        <li
          aria-hidden='true'
          className='ais-InfiniteHits-sentinel'
          ref={sentinelRef}
        />
      </ul>
    </div>
  );
};

export default InstantSearchInfiniteScroll;
