import {
  IMAGEKIT_DAM_ROOT_DIRECTORY,
  LINE_ITEM_SOURCE,
  MEDIA_SOURCE,
  mediaStoreBaseURL,
  NO_PRODUCT_IMAGE,
  STATIC_MEDIA_STORE_PATH_STUB,
  staticMediaStoreBaseURL
} from '@/config/common';
import { arrayOfObjectHasKey } from '@/lib/utils';

const imageKitMediaURL = process.env.NEXT_PUBLIC_MEDIA_STORE_IMAGE_KIT_ORIGIN
  ? `${process.env.NEXT_PUBLIC_MEDIA_STORE_IMAGE_KIT_ORIGIN}${IMAGEKIT_DAM_ROOT_DIRECTORY}`
  : STATIC_MEDIA_STORE_PATH_STUB;

const localHostMediaURL = '';

export const GRID_BOXES_PER_ROW = 5;

export const GRID_BOX_HEIGHT = 205;

export const getDropZoneHeight = ({ mediaList }) =>
  Math.ceil(mediaList.length / GRID_BOXES_PER_ROW) * GRID_BOX_HEIGHT;

const removeLeadingBackSlash = (str = '') => str.replace(/^\//, '');

const noProductImageURL = `${STATIC_MEDIA_STORE_PATH_STUB}${NO_PRODUCT_IMAGE.url}`;

export const itemImageDefault = {
  defaultImage: true,
  id: 1,
  imageTitle: 'default-product-image',
  original: noProductImageURL,
  sortOrder: 1,
  source: MEDIA_SOURCE.AWS_S3,
  thumbnail: noProductImageURL,
  url: noProductImageURL
};

const patchOriginForStorybook = (origin) =>
  origin || STATIC_MEDIA_STORE_PATH_STUB;

export const getImageURL = ({ source, url: urlPath }) => {
  let origin = '';
  switch (source) {
    case MEDIA_SOURCE.CODE_REPO:
      origin = staticMediaStoreBaseURL;
      break;
    case MEDIA_SOURCE.IMAGE_KIT:
      origin = imageKitMediaURL;
      break;
    case MEDIA_SOURCE.LOCALHOST:
      origin = localHostMediaURL;
      break;
    case MEDIA_SOURCE.AWS_S3:
    default:
      origin = mediaStoreBaseURL;
      break;
  }
  origin = patchOriginForStorybook(origin);
  return urlPath ? `${origin}/${removeLeadingBackSlash(urlPath)}` : '';
};

const checkAndUpdateSortOrder = ({ itemMediaList = [] }) => {
  const itemMediaHasSortOrderKey = arrayOfObjectHasKey({
    array: itemMediaList,
    key: 'sortOrder'
  });
  if (itemMediaHasSortOrderKey) {
    return itemMediaList;
  }
  return itemMediaList.map((item, index) => ({
    ...item,
    sortOrder: index + 1
  }));
};

const transformMediaListWithDetails = ({ entityType, items, source }) =>
  items
    .map(({ id: itemMediaId, media, sortOrder }) => ({
      ...media,
      [`${
        source === LINE_ITEM_SOURCE.CART.value
          ? 'cartItemMediaId'
          : 'orderItemMediaId'
      }`]: itemMediaId,
      entityType,
      sortOrder
    }))
    .sort((a, b) => a.sortOrder - b.sortOrder);

export const itemMediaListMapper = ({
  additionalMediaList: additionalMediaListRaw,
  ...item
}) => {
  const { product, source } = item;

  const productMedia = product?.productMedia || [];

  const additionalMediaListForCarousel = transformMediaListWithDetails({
    entityType:
      source === LINE_ITEM_SOURCE.CART.value ? 'cartItem' : 'orderItem',
    items: checkAndUpdateSortOrder({ itemMediaList: additionalMediaListRaw }),
    source
  });

  const baseSortOrderToAccommodateHoles =
    additionalMediaListForCarousel.length;

  const productMediaForCarousel = transformMediaListWithDetails({
    entityType: 'product',
    items: productMedia,
    source
  }).map(({ sortOrder, ...image }) => ({
    ...image,
    sortOrder: sortOrder + baseSortOrderToAccommodateHoles
  }));

  const itemMediaForCarousel = [
    ...additionalMediaListForCarousel,
    ...productMediaForCarousel
  ];

  return {
    ...item,
    itemMediaForCarousel:
      itemMediaForCarousel.length === 0
        ? [itemImageDefault]
        : itemMediaForCarousel
  };
};

const sortArrayOfObjectByKey = (array, key) =>
  array.sort((a, b) => (a[`${key}`] > b[`${key}`] ? 1 : -1));

export const getRankedImages = (images) => {
  const hasRank = images.length === images.every(({ rank }) => rank);
  if (hasRank) {
    return sortArrayOfObjectByKey(images, 'rank');
  }

  const hasSortOrder =
    images.length === images.every(({ sortOrder }) => sortOrder);
  if (hasSortOrder) {
    sortArrayOfObjectByKey(images, 'sortOrder').map((image, index) => ({
      ...image,
      rank: index + 1
    }));
  }

  return images.map((image, index) => ({
    ...image,
    source: image.source || MEDIA_SOURCE.AWS_S3,
    rank: index + 1
  }));
};

export const downloadImage = async ({ imageUrl, filename }) => {
  const image = await fetch(imageUrl);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);
  const link = document.createElement('a');
  link.href = imageURL;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
