import { HOST_TYPES_WITH_ADDITIONAL_REQUIREMENTS } from '@/config/common';
import {
  getHostDummyEmail,
  isNilOrEmpty,
  rectifyAndReplaceEmptyStringWithNull
} from '@/lib/utils';
import { getHostSegmentListAPI } from '@/services/addHost.service';
import * as HttpService from '@/services/http.service';
import { apiResponseParser } from '@/services/plannerPortal.service';
import {
  GET_ETHNICITIES_API_URL,
  GET_HOST_PROFILE_WITH_STATS_API_URL,
  GET_LANGUAGES_API_URL,
  UPDATE_HOST_API_URL
} from '@/services/url.service';

const getHostProfileAPI = ({ hostId }) =>
  HttpService.getWithAuth(GET_HOST_PROFILE_WITH_STATS_API_URL({ hostId }));

const getLanguageListAPI = () =>
  HttpService.getWithAuth(GET_LANGUAGES_API_URL());

const getEthnicityListAPI = () =>
  HttpService.getWithAuth(GET_ETHNICITIES_API_URL());

export const updateHostAPI = ({ hostId, dataToUpdate }) =>
  HttpService.putWithAuth(UPDATE_HOST_API_URL({ hostId }), dataToUpdate);

export const fetchHostProfile = async ({
  hostId,
  setHostCreditEntityId,
  setHostProfile,
  setLoading = () => {},
  setShowToast = () => {}
}) => {
  setLoading(true);
  const { entity, message, status } = await getHostProfileAPI({ hostId });
  if (status && entity.user) {
    setHostProfile(entity);
    setHostCreditEntityId(entity.user?.hostCreditEntityId);
  } else {
    setShowToast({ message, show: true, successToast: status });
  }
  setLoading(false);
};

export const updateHostProfile = async ({
  dataToUpdate,
  setHostCreditEntityId,
  setHostProfile,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const {
    consentForWhatsapp,
    hostSegmentId,
    email,
    ethnicityId,
    hostCreditEntityId,
    id: hostId,
    mobile,
    name,
    preferredLanguageId,
    socialProfiles
  } = rectifyAndReplaceEmptyStringWithNull(dataToUpdate);
  const { message, status } = await updateHostAPI({
    hostId,
    dataToUpdate: {
      consentForWhatsapp,
      hostSegmentId,
      email: isNilOrEmpty(email) ? getHostDummyEmail(mobile) : email,
      ethnicityId,
      hostCreditEntityId,
      mobile,
      name,
      preferredLanguageId,
      socialProfiles
    }
  });
  if (status) {
    await fetchHostProfile({ hostId, setHostCreditEntityId, setHostProfile });
  }
  setLoading(false);
  setShowToast({
    message: status ? 'Changes Saved Successfully' : message,
    show: true,
    successToast: status
  });
};

const listOfSocialProfileDetails = [
  {
    alt: 'facebook',
    dbName: 'socialProfileFb',
    icon: {
      activeIcon: 'facebook-rounded-red-icon.svg',
      disabledIcon: 'facebook-rounded-black-icon.svg',
      disabledSelectedIcon: 'facebook-fill-icon-black.svg',
      selectedIcon: 'facebook-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'facebook-rounded-red-icon.svg',
      disabledIcon: 'facebook-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'facebook',
    sortOrder: 1
  },
  {
    alt: 'twitter',
    dbName: 'socialProfileTwitter',
    icon: {
      activeIcon: 'twitter-rounded-red-icon.svg',
      disabledIcon: 'twitter-rounded-black-icon.svg',
      disabledSelectedIcon: 'twitter-fill-icon-black.svg',
      selectedIcon: 'twitter-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'twitter-rounded-red-icon.svg',
      disabledIcon: 'twitter-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'twitter',
    sortOrder: 8
  },
  {
    alt: 'tiktok',
    dbName: 'socialProfileTiktok',
    icon: {
      activeIcon: 'tiktok-rounded-red-icon.svg',
      disabledIcon: 'tiktok-rounded-black-icon.svg',
      disabledSelectedIcon: 'tiktok-fill-icon-black.svg',
      selectedIcon: 'tiktok-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'tiktok-rounded-red-icon.svg',
      disabledIcon: 'tiktok-rounded-black-icon.svg'
    },

    isSelected: false,
    name: 'tiktok',
    sortOrder: 7
  },
  {
    alt: 'youtube',
    dbName: 'socialProfileYoutube',
    icon: {
      activeIcon: 'youtube-rounded-red-icon.svg',
      disabledIcon: 'youtube-rounded-black-icon.svg',
      disabledSelectedIcon: 'youtube-fill-icon-black.svg',
      selectedIcon: 'youtube-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'youtube-rounded-red-icon.svg',
      disabledIcon: 'youtube-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'youtube',
    sortOrder: 6
  },
  {
    alt: 'pinterest',
    dbName: 'socialProfilePintrest',
    icon: {
      activeIcon: 'pinterest-rounded-red-icon.svg',
      disabledIcon: 'pinterest-rounded-black-icon.svg',
      disabledSelectedIcon: 'pinterest-fill-icon-black.svg',
      selectedIcon: 'pinterest-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'pinterest-rounded-red-icon.svg',
      disabledIcon: 'pinterest-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'pintrest',
    sortOrder: 5
  },
  {
    alt: 'linked',
    dbName: 'socialProfileLinkedin',
    icon: {
      activeIcon: 'linkedin-rounded-red-icon.svg',
      disabledIcon: 'linkedin-rounded-black-icon.svg',
      disabledSelectedIcon: 'linkedin-fill-icon-black.svg',
      selectedIcon: 'linkedin-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'linkedin-rounded-red-icon.svg',
      disabledIcon: 'linkedin-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'linked in',
    sortOrder: 4
  },
  {
    alt: 'website',
    dbName: 'socialProfileWebsite',
    icon: {
      activeIcon: 'web-rounded-red-icon.svg',
      disabledIcon: 'web-rounded-black-icon.svg',
      disabledSelectedIcon: 'web-fill-icon-black.svg',
      selectedIcon: 'web-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'web-rounded-red-icon.svg',
      disabledIcon: 'web-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'website',
    sortOrder: 3
  },
  {
    alt: 'instagram',
    dbName: 'socialProfileInsta',
    icon: {
      activeIcon: 'instagram-rounded-red-icon.svg',
      disabledIcon: 'instagram-rounded-black-icon.svg',
      disabledSelectedIcon: 'instagram-fill-icon-black.svg',
      selectedIcon: 'instagram-fill-red-icon.svg'
    },
    inputIcon: {
      activeIcon: 'instagram-rounded-red-icon.svg',
      disabledIcon: 'instagram-rounded-black-icon.svg'
    },
    isSelected: false,
    name: 'instagram',
    sortOrder: 2
  }
];

export const updateSocialMediaProfileBasedOnValue = ({ socialProfiles }) =>
  listOfSocialProfileDetails.map(({ dbName, ...rest }) => ({
    ...rest,
    dbName,
    isSelected: Boolean(socialProfiles?.[dbName])
  }));

export const fetchMetaDataAPIs = async ({
  setHostProfileMetadata,
  setShowToast
}) => {
  const result = await apiResponseParser({
    listOfAPI: [
      getHostSegmentListAPI(),
      getEthnicityListAPI(),
      getLanguageListAPI()
    ],
    setShowToast
  });
  if (result) {
    const [hostTypeList, ethnicityList, preferredLanguageList] = result;
    setHostProfileMetadata({
      ethnicityList,
      hostTypeList,
      preferredLanguageList
    });
  }
};

export const hostProfileTabs = {
  HOST_PROFILE: {
    id: '#host-profile-tab',
    label: 'Host Profile'
  },
  ADDITIONAL_PROFILE: {
    id: '#additional-detail-tab',
    label: 'Additional Details'
  }
};

export const hostProfileStepFieldErrorConfig = {
  [hostProfileTabs.ADDITIONAL_PROFILE.id]: [],
  [hostProfileTabs.HOST_PROFILE.id]: ['hostCreditEntityId', 'name']
};

export const validateHostCreditEntityId = ({
  hostSegmentId,
  hostCreditEntityId,
  hostTypeList
}) => {
  const validationNotRequired = !hostSegmentId || hostCreditEntityId;
  if (validationNotRequired) {
    return validationNotRequired;
  }
  const { name: hostTypeName } = hostTypeList.find(
    ({ id }) => id === hostSegmentId
  );
  return !HOST_TYPES_WITH_ADDITIONAL_REQUIREMENTS.includes(hostTypeName);
};
