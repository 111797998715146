import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import { PRODUCT_TYPE } from '@/config/common';
import {
  parseFormatPercentValueFromAPI,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { getRackPriceForUnOrderedCartItem } from '@/services/cart.plan.service';

const DiscountPercentWithPriceStrike = ({
  unitRackPrice,
  discountPercent
}) => (
  <TextKeyValuePair
    {...{
      className: 'flex gap-2',
      label: `AED ${unitRackPrice}`,
      labelClass: 'line-through text-xs text-dim-gray self-center',
      value: `${discountPercent}% off`,
      valueClassName: 'text-green text-md font-medium'
    }}
  />
);

const RackRateOfUnOrderedCartItem = ({ product, unitRackPrice }) => {
  const rackPriceString = getRackPriceForUnOrderedCartItem({
    ...product
  });

  const showDiscountPercentWithPriceStrike =
    product.type === PRODUCT_TYPE.CART && product.availableDiscount?.isActive;

  return (
    <>
      <Text {...{ content: rackPriceString }} />
      {showDiscountPercentWithPriceStrike && (
        <DiscountPercentWithPriceStrike
          {...{
            discountPercent: parseFormatPercentValueFromAPI(
              product.availableDiscount?.value || 0
            ),
            unitRackPrice: parseFormatPriceValueFromAPI(unitRackPrice)
          }}
        />
      )}
    </>
  );
};

export default RackRateOfUnOrderedCartItem;
