import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { TAG_OPTIONS_SELECTION_MODE } from '@/config/common';
import useEventListener from '@/hooks/useEventListener';

const getClassNames = ({
  id,
  isEditable,
  selectedTagOptions,
  tagOptionsSelectionMode
}) => {
  const isSelected =
    tagOptionsSelectionMode === TAG_OPTIONS_SELECTION_MODE.SINGLE
      ? selectedTagOptions === id
      : selectedTagOptions.includes(id);

  const selectionClasses = isSelected
    ? 'selected border-brand text-nero'
    : 'border-platinum text-dim-gray';

  return `rounded-lg border p-3 font-medium
    ${isEditable ? 'hover:border-brand' : ' bg-neutral'}
    ${selectionClasses}`;
};

const SelectTags = ({
  containerStyle = 'flex-col gap-2',
  errorMessage = null,
  formActiveStepId,
  isEditable = false,
  selectedTagOptions,
  setSelectedTagOptions,
  tagOptions,
  tagOptionsSelectionMode = TAG_OPTIONS_SELECTION_MODE.MULTIPLE, // TODO: HP/FV: can we remove tag prefix as component already descriptive as select tags. https://bitbucket.org/evinops-hafla/hafla/pull-requests/10960#comment-578934996
  tagsFieldLabel: { label, labelStyle = 'text-base font-medium' } = {}
}) => {
  const [expanded, setExpanded] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const listRef = useRef(null);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const toggleTagSelection = (tag) => {
    if (tagOptionsSelectionMode === TAG_OPTIONS_SELECTION_MODE.SINGLE) {
      setSelectedTagOptions(selectedTagOptions === tag ? null : tag);
      return;
    }
    setSelectedTagOptions((prev) =>
      prev.includes(tag) ? prev.filter((v) => v !== tag) : [...prev, tag]
    );
  };

  const displayShowMoreButton = () => {
    const list = listRef.current;
    if (!list) return;
    const containerWidth = list.offsetWidth;
    const totalItemWidth = _.sumBy([...list.children], 'offsetWidth');
    setShowMoreButton(totalItemWidth > containerWidth);
  };

  useEventListener('resize', displayShowMoreButton);

  useEffect(() => {
    displayShowMoreButton();
  }, [tagOptions, formActiveStepId]);

  const showLabel = Boolean(label);

  const sortedTagOptions = _.sortBy(tagOptions, ({ id }) =>
    tagOptionsSelectionMode === TAG_OPTIONS_SELECTION_MODE.SINGLE
      ? selectedTagOptions !== id
      : !selectedTagOptions.includes(id)
  );

  return (
    <div className={`flex ${containerStyle}`}>
      {showLabel && <label className={labelStyle}>{label}</label>}
      <div
        className={`gap-4 flex flex-wrap overflow-hidden ${
          expanded ? 'max-h-full' : 'max-h-[60px]'
        }`}
        ref={listRef}
      >
        {sortedTagOptions.map(({ id, name }) => (
          <button
            key={id}
            className={getClassNames({
              id,
              isEditable,
              selectedTagOptions,
              tagOptionsSelectionMode
            })}
            onClick={() => isEditable && toggleTagSelection(id)}
          >
            {name}
          </button>
        ))}
      </div>
      {showMoreButton && (
        <button
          className='self-end text-brand text-sm font-medium mt-1'
          disabled={!isEditable}
          onClick={toggleExpand}
        >
          {expanded ? 'Show Less' : 'Show More'}
        </button>
      )}
      {errorMessage && (
        <div className='text-xs text-red-500'>{errorMessage}</div>
      )}
    </div>
  );
};

export default SelectTags;
