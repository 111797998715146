import { useState } from 'react';

import { ShowMoreLessCTA, Text } from '@/components/atomic/nuclei';
import { BID_ITEM_STATUS } from '@/config/common';
import { parseFormatPriceValueFromAPI } from '@/lib/numberStringUtils';
import {
  getTenderItemAskPrice,
  getTenderItemOfferPrice,
  tenderItemBidHeaderConfig
} from '@/services/tenderResponse.service';

const COMMENT_LENGTH = 100;

const BidItemHeader = () => (
  <div className='flex border border-b-0 bg-white justify-between py-5 px-4 text-10px font-semibold uppercase rounded-t-2.5'>
    {tenderItemBidHeaderConfig.map(({ content, className }, id) => (
      <Text
        key={id}
        {...{ content, className }}
      />
    ))}
  </div>
);

const BidItemDetail = ({ bidItem, isLastItem }) => {
  const { partnerNotes, name, quantityRequested, status } = bidItem;
  const comment = status !== BID_ITEM_STATUS.PENDING.value && partnerNotes;
  const [showMore, setShowMore] = useState(comment?.length > COMMENT_LENGTH);
  const tenderItemOfferedPriceStringified = String(
    parseFormatPriceValueFromAPI(getTenderItemOfferPrice({ bidItem }))
  );
  return (
    <div
      className={`flex border ${
        isLastItem ? 'rounded-b-2.5' : ''
      } bg-white text-nero justify-between py-6 border-t border-b px-4 text-xs self-center`}
    >
      <Text
        {...{
          className: 'w-60 flex self-center',
          content: name || '-'
        }}
      />
      <Text
        {...{
          className: 'w-32 flex self-center',
          content: quantityRequested ? String(quantityRequested) : '-'
        }}
      />
      <Text
        {...{
          className: 'w-24 flex self-center',
          content: String(
            parseFormatPriceValueFromAPI(getTenderItemAskPrice({ bidItem }))
          )
        }}
      />
      <div className='flex w-24'>
        <div className='flex gap-2'>
          <div
            className={`w-2 h-3 flex self-center rounded ${BID_ITEM_STATUS[status].className}`}
          ></div>
          <Text
            {...{
              className: 'flex self-center',
              content: BID_ITEM_STATUS[status].label
            }}
          />
        </div>
      </div>
      <Text
        {...{
          className: 'w-28 flex self-center',
          content:
            status !== BID_ITEM_STATUS.PENDING.value
              ? tenderItemOfferedPriceStringified
              : '-'
        }}
      />
      <ShowMoreLessCTA
        {...{
          className: 'w-128',
          setShowMore,
          showMore,
          textValue: comment
        }}
      />
    </div>
  );
};

const BidItemResponse = ({ bidItems = [], isEvenRow }) => {
  const rowCount = bidItems.length;
  return (
    <div
      className={`${
        isEvenRow ? 'bg-platinum' : 'bg-white'
      } text-silver border-l border-r border-b p-6`}
    >
      <BidItemHeader />
      {bidItems.map((bidItem, index) => (
        <BidItemDetail
          key={index}
          {...{ bidItem, isLastItem: index + 1 === rowCount }}
        />
      ))}
    </div>
  );
};

export default BidItemResponse;
