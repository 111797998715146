import Image from 'next/image';
import { Modal, ModalBody } from 'reactstrap';

import { EPICExplanation } from '@/components/atomic/atoms';
import { Button, Text, TextWithIcon } from '@/components/atomic/nuclei';
import {
  mediaStoreBaseURL,
  staticMediaStoreBaseURL,
  USER_EVENT_ACTION,
  USER_EVENT_SOURCE
} from '@/config/common';
import { useIsMobile } from '@/lib/screenResolution';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { updateAllocationStatus } from '@/services/actionCenter.service';

const RequestReassignModal = ({
  router,
  setActionModalRequest,
  setLoading,
  setShowToast,
  userEvent
}) => {
  const {
    eventStageUpdatedAt,
    user,
    userEventDetails: [
      {
        checkoutEvent = {},
        eventAddress = {},
        eventContactName,
        eventDate = '',
        eventVerticals,
        expectedGuestCount,
        userEventId
      } = {}
    ] = [],
    userEventNumber,
    zendeskTicketId
  } = userEvent || {};
  const { hostCreditEntity, hostSegment } = user || {};

  const requesterType = hostSegment?.name || null;

  const [isMobile] = useIsMobile();

  const epicParameters = {
    city: eventAddress?.formattedAddress,
    eventDate,
    eventType: checkoutEvent?.event?.name,
    guests: expectedGuestCount,
    hostCreditEntityName: hostCreditEntity?.name || 'NA',
    hostName: eventContactName,
    hostType: requesterType,
    userEventId,
    verticals: eventVerticals?.map(({ name }) => name).join(', ')
  };

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={Boolean(userEvent)}
      size='lg'
    >
      <ModalBody>
        <div className='md:mx-auto md:w-[720px] my-10 mx-2 relative'>
          <div className='flex flex-col gap-2 rounded-t-lg create-event-bg text-white'>
            <div className='w-28 ml-auto'>
              <TextWithIcon
                {...{
                  alt: 'time red icon',
                  className:
                    'shadow-none py-[1px] absolute top-[1px] right-[1px] bg-white rounded-bl-[7px] rounded-tr-[7px] px-1 z-[8]',
                  gap: 'gap-[2px] md:gap-2',
                  icon: 'time-icon-red.svg',
                  iconHeight: isMobile ? 8 : 12,
                  iconWidth: isMobile ? 8 : 12,
                  label: getFormattedDate({
                    date: eventStageUpdatedAt,
                    format: 'Do MMM YYYY, hh.mm A'
                  }),
                  labelStyle:
                    'text-[8px] md:text-[10px] font-light md:font-medium text-dim-gray'
                }}
              />
            </div>
            <div className='p-3 pt-0'>
              <div className='flex items-center gap-2 md:gap-4 mt-3'>
                <Image
                  alt='event image'
                  className='rounded-lg'
                  height={0}
                  src={`${mediaStoreBaseURL}/${checkoutEvent.iconUrl}`}
                  style={{
                    height: isMobile ? 48 : 72,
                    width: isMobile ? 48 : 72
                  }}
                  unoptimized={true}
                  width={0}
                />
                <Text
                  {...{
                    className: 'text-sm text-white truncate-text-three-line',
                    content: (
                      <EPICExplanation
                        {...{
                          epicParameters
                        }}
                      />
                    )
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-6 p-3 bg-white rounded-b-lg'>
            <div className='flex items-center justify-between'>
              <div className='flex gap-4'>
                <TextWithIcon
                  {...{
                    alt: 'zendesk icon',
                    className: '',
                    gap: 'gap-[5px]',
                    icon: 'zendesk-icon.svg',
                    iconHeight: 20,
                    iconWidth: 20,
                    label: parseNumberedLabel(zendeskTicketId),
                    labelStyle: 'text-xs md:text-sm font-medium text-dim-gray'
                  }}
                />
                <TextWithIcon
                  {...{
                    alt: 'user event brand icon',
                    className: '',
                    gap: 'gap-[5px]',
                    icon: 'user-event-brand-icon.svg',
                    iconHeight: 20,
                    iconWidth: 20,
                    label: parseNumberedLabel(userEventNumber),
                    labelStyle: 'text-xs md:text-sm font-medium text-dim-gray'
                  }}
                />
              </div>
            </div>
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3'>
                <Button
                  {...{
                    alt: 'radio',
                    className:
                      'border border-[#E5E5EB] rounded-lg py-2 text-dim-gray font-medium',
                    disabled: true,
                    iconPosition: 'Left',
                    iconURL: `${staticMediaStoreBaseURL}/icons/radio.svg`,
                    label: USER_EVENT_ACTION.ACCEPT.label
                  }}
                />
                <Button
                  {...{
                    alt: 'radio checked brand',
                    className:
                      'border border-brand rounded-lg py-2 text-dim-gray font-medium',
                    iconPosition: 'Left',
                    iconURL: `${staticMediaStoreBaseURL}/icons/radio-checked-brand.svg`,
                    label: USER_EVENT_ACTION.REASSIGN.label
                  }}
                />
              </div>
              <div className='flex gap-3'>
                <Button
                  {...{
                    className:
                      'bg-neutral text-dim-gray text-sm font-medium py-3 rounded-lg',
                    label: 'Close',
                    onClick: () => setActionModalRequest(null),
                    width: 'w-[336px]'
                  }}
                />
                <Button
                  {...{
                    className:
                      'bg-brand-gradient text-white text-sm font-medium py-3 rounded-lg',
                    label: 'Confirm',
                    onClick: () =>
                      updateAllocationStatus({
                        payload: {
                          allocationStatus: USER_EVENT_ACTION.REASSIGN.value,
                          source:
                            USER_EVENT_SOURCE.PLANNER_PORTAL_UPDATE_USER_EVENT,
                          zendeskTicketId
                        },
                        router,
                        setLoading,
                        setShowToast,
                        userEventId
                      }),
                    width: 'w-[336px]'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestReassignModal;
