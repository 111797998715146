import { getSerializedRichText } from '@/lib/editor';
import { convertToShortMonthDateFormat } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import {
  addHostAPI,
  fetchHostByMobile,
  getHostSegmentListAPI
} from '@/services/addHost.service';
import { getOpsUserListAPI } from '@/services/hostCreditEntity.service';
import {
  getCheckoutEventsAPI,
  getCityDetailsAPI,
  getEventVerticalsAPI
} from '@/services/hostEvent.service';
import {
  updateHostAPI,
  validateHostCreditEntityId
} from '@/services/hostProfile.service';
import * as HttpService from '@/services/http.service';
import { apiResponseParser } from '@/services/plannerPortal.service';
import { CREATE_USER_EVENT_PROFILE_API_URL } from '@/services/url.service';

const createHostEventProfileAPI = (data) =>
  HttpService.postWithAuth(CREATE_USER_EVENT_PROFILE_API_URL(), data);

export const fetchMetaDataAPIs = async ({
  setLoading,
  setShowToast,
  setUserEventProfileMetadata
}) => {
  setLoading(true);
  const result = await apiResponseParser({
    listOfAPI: [
      getCheckoutEventsAPI(),
      getCityDetailsAPI(),
      getEventVerticalsAPI(),
      getHostSegmentListAPI(),
      getOpsUserListAPI()
    ],
    setShowToast
  });
  setLoading(false);
  if (result) {
    const [checkoutEvents, cityList, eventVerticals, hostTypeList, opsUsers] =
      result;
    const parseCheckoutEvents = checkoutEvents.map(
      ({ event: { name }, id }) => ({ id, name })
    );
    const { rows: opsUserList } = opsUsers;
    setUserEventProfileMetadata({
      checkoutEvents: parseCheckoutEvents,
      cityList,
      eventVerticals,
      hostTypeList,
      opsUsers: opsUserList
    });
  }
};

const createHostProfile = async ({
  dataToSubmit,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { status, message, entity } = await addHostAPI(dataToSubmit);
  setLoading(false);
  if (status) {
    return entity;
  }
  setShowToast({
    message,
    show: true,
    successToast: false
  });
  return null;
};

const updateHostProfile = async ({
  dataToUpdate,
  hostId,
  setLoading,
  setShowToast
}) => {
  const {
    consentForWhatsapp,
    email,
    ethnicityId,
    hostCreditEntityId,
    hostSegmentId,
    mobile,
    name,
    preferredLanguageId,
    socialProfiles
  } = dataToUpdate;
  setLoading(true);
  const { entity, message, status } = await updateHostAPI({
    hostId,
    dataToUpdate: {
      consentForWhatsapp,
      email,
      ethnicityId,
      hostCreditEntityId,
      hostSegmentId,
      mobile,
      name,
      preferredLanguageId,
      socialProfiles
    }
  });
  setLoading(false);
  if (status) {
    return entity;
  }
  setShowToast({
    message,
    show: true,
    successToast: status
  });
  return null;
};

const createUserEventProfile = async ({
  dataToAdd,
  setLoading,
  setShowToast,
  setUserEventProfileDetail
}) => {
  setLoading(true);
  const { entity, message, status } =
    await createHostEventProfileAPI(dataToAdd);
  setLoading(false);
  if (status) {
    setUserEventProfileDetail(entity);
  } else {
    setShowToast({
      message,
      show: true,
      successToast: status
    });
  }
};

const getEventVerticalsName = (eventVerticals) =>
  eventVerticals.map(({ name }) => name).join(', ');

export const buildHostEventSummaryList = ({
  user: {
    hostCreditEntity,
    hostSegment: { name: hostSegmentName },
    name: hostName
  },
  userEventDetails: [
    {
      eventAddress,
      eventDate,
      eventVerticals,
      expectedGuestCount,
      triageSummary
    }
  ],
  zendeskTicketId
}) => [
  {
    alt: 'zendesk ticket number',
    icon: 'zendesk-with-frame.svg',
    label: parseNumberedLabel(zendeskTicketId)
  },
  {
    alt: 'host name icon',
    icon: 'host-white-v1-icon.svg',
    label: hostName
  },
  {
    alt: 'credit entity name',
    icon: 'tab-host-credit-entity.svg',
    label: hostCreditEntity?.name || 'NA'
  },
  {
    alt: 'host type icon',
    icon: 'host-type-white-v1-icon.svg',
    label: hostSegmentName
  },
  {
    alt: 'event date',
    icon: 'event-date-icon.svg',
    label: eventDate ? convertToShortMonthDateFormat(eventDate) : 'NA'
  },
  {
    alt: 'location',
    icon: 'location-white-icon.svg',
    label: eventAddress?.formattedAddress || 'NA'
  },
  {
    alt: 'core verticals icon',
    icon: 'core-verticals-white-icon.svg',
    label: getEventVerticalsName(eventVerticals)
  },
  {
    alt: 'guest count',
    icon: 'guests-white-icon.svg',
    label: expectedGuestCount
  },
  {
    alt: 'triage summary',
    icon: 'summary-icon-white.svg',
    label: triageSummary ? getSerializedRichText(triageSummary) : 'NA'
  }
];

export const validateIfMobileNumberMatch = async ({
  getValues,
  mobile,
  setExistingHostProfile,
  setIsNewOrExistingMobileNumberChecked,
  setIsValidHostTypeForCreditEntity,
  setSelectedHostCreditEntityName,
  setValue,
  trigger
}) => {
  const matchingHost = await fetchHostByMobile({ mobile });
  setExistingHostProfile(matchingHost);
  setIsNewOrExistingMobileNumberChecked(true);
  if (matchingHost?.id) {
    setValue('customerName', matchingHost?.name);
    setValue('hostCreditEntityId', matchingHost?.hostCreditEntity?.id);
    setValue('hostSegmentId', matchingHost?.hostSegmentId);
    setSelectedHostCreditEntityName(matchingHost?.hostCreditEntity?.name);
    trigger();
    if (matchingHost?.hostSegmentId) {
      setIsValidHostTypeForCreditEntity(
        !validateHostCreditEntityId({
          hostSegmentId: matchingHost?.hostSegmentId,
          hostCreditEntityId: null,
          hostTypeList: getValues('hostTypeList')
        })
      );
    }
  }
};

export const submitUpsertHostCreateEventDetails = async ({
  eventDetails,
  hostProfileDetail,
  selectedEventRequestVerticals,
  setLoading,
  setShowToast,
  setUserEventProfileDetail
}) => {
  let upsertAction;
  let upsertPayload;

  if (hostProfileDetail?.userId) {
    upsertAction = updateHostProfile;
    upsertPayload = {
      dataToUpdate: hostProfileDetail,
      hostId: hostProfileDetail.userId
    };
  } else {
    upsertAction = createHostProfile;
    upsertPayload = {
      dataToSubmit: hostProfileDetail
    };
  }

  const hostDetail = await upsertAction({
    ...upsertPayload,
    setLoading,
    setShowToast
  });

  if (hostDetail?.id) {
    const eventProfileDetail = {
      ...eventDetails,
      eventVerticalIds: selectedEventRequestVerticals,
      userId: hostDetail.id
    };
    await createUserEventProfile({
      dataToAdd: eventProfileDetail,
      setLoading,
      setShowToast,
      setUserEventProfileDetail
    });
  }
};
