import Image from 'next/image';
import { useState } from 'react';

import {
  ItemCommonDetails,
  UploadCartItemMediaModal
} from '@/components/atomic/atoms';
import { LineItemMedia } from '@/components/atomic/molecules';
import { ImageCarouselModal, Text } from '@/components/atomic/nuclei';
import { PRODUCT_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { getSerializedRichText } from '@/lib/editor';
import { parseFormatPriceNumberValueFromAPI } from '@/lib/numberStringUtils';
import { useIsMobile } from '@/lib/screenResolution';

const OrderItemSummaryCardOrganism = ({
  orderItem,
  setItemInDetailView,
  setShowToast
}) => {
  const {
    id,
    itemMediaForCarousel,
    orderId,
    productName,
    productNotes,
    productSource,
    productStatus,
    quantity,
    showProductMedia,
    unitListedPrice
  } = orderItem;

  const itemTextClass =
    productStatus === PRODUCT_STATUS.UNPUBLISHED
      ? 'text-error-base'
      : 'text-rich-black';

  const [isMobile] = useIsMobile();
  const [imageCarouselModalImage, setImageCarouselModalImage] =
    useState(false);
  const [orderItemIdForUploadMediaModal, setOrderItemIdForUploadMediaModal] =
    useState(null);
  const thumbnailImage = itemMediaForCarousel[0];

  const isProductMediaHidden = !showProductMedia;
  return (
    <div>
      <div className='flex gap-4 rounded-xl shadow-card border border-neutral cursor-pointer h-24 md:h-36'>
        <div
          className={`w-28 relative md:w-40 h-full flex flex-col self-center order-line-item ${
            isProductMediaHidden ? 'rounded-l-lg border-2 border-orange' : ''
          }`}
        >
          <LineItemMedia
            {...{
              isMobile,
              itemId: id,
              itemMediaForCarousel,
              itemParentId: orderId,
              productName,
              productSource,
              setImageCarouselModalImage,
              setOrderItemIdForUploadMediaModal,
              setShowToast,
              showProductMedia,
              thumbnailImage
            }}
          />
        </div>
        <div
          className='flex flex-1 justify-between'
          onClick={() => setItemInDetailView(id)}
        >
          <div className='flex flex-col self-center md:gap-2'>
            <Text
              {...{
                className: `font-medium text-xs md:text-base max-w-28 md:max-w-116.5 truncate ${itemTextClass}`,
                content: productName
              }}
            />
            <ItemCommonDetails
              {...{
                containerClass: itemTextClass,
                priceLabel: parseFormatPriceNumberValueFromAPI(
                  unitListedPrice * quantity
                ),
                quantity
              }}
            />
            {getSerializedRichText(productNotes) && (
              <div className={`${itemTextClass} flex gap-1`}>
                <Text
                  className='text-xs md:text-base text-rich-black font-medium'
                  content='Details: '
                />
                <Text
                  {...{
                    className:
                      'text-xs md:text-base font-light max-w-28 md:max-w-116.5 truncate h-5',
                    content: getSerializedRichText(productNotes)
                  }}
                />
              </div>
            )}
          </div>
          <div className='self-center ml-auto mr-4 flex h-32 md:h-36 items-center'>
            <Image
              alt='view item'
              height={0}
              src={`${staticMediaStoreBaseURL}/icons/right-angle-red-bg.svg`}
              style={{ height: isMobile ? 16 : 32, width: isMobile ? 16 : 32 }}
              width={0}
            />
          </div>
        </div>
      </div>
      {imageCarouselModalImage && (
        <ImageCarouselModal
          {...{
            images: itemMediaForCarousel,
            modalImage: imageCarouselModalImage,
            openImageModal: setImageCarouselModalImage
          }}
        />
      )}
      {orderItemIdForUploadMediaModal && (
        <UploadCartItemMediaModal
          {...{
            orderId,
            orderItemId: orderItemIdForUploadMediaModal,
            productSource,
            setOrderItemIdForUploadMediaModal,
            setShowToast
          }}
        />
      )}
    </div>
  );
};

export default OrderItemSummaryCardOrganism;
