import googleLibPhoneNumber from 'google-libphonenumber';

const phoneUtil = googleLibPhoneNumber.PhoneNumberUtil.getInstance();
const { PhoneNumberType } = googleLibPhoneNumber;

const getPhoneNumberDetails = (inputMobileNumber) => {
  try {
    const phoneNumberObject =
      phoneUtil.parseAndKeepRawInput(inputMobileNumber);
    const countryCode = `+${phoneNumberObject.getCountryCode()}`;
    const regionCode = phoneUtil.getRegionCodeForNumber(phoneNumberObject);
    const nationalNumber = `${phoneNumberObject.getNationalNumber()}`;
    const numberDetails = {
      countryCode,
      dialCode: countryCode,
      isValidNumberForRegion: regionCode,
      nationalNumber,
      number: nationalNumber,
      numberType: phoneUtil.getNumberType(phoneNumberObject),
      regionCodeForNumber: regionCode
    };
    numberDetails.isValidNumberForRegion = phoneUtil.isValidNumberForRegion(
      phoneNumberObject,
      numberDetails.regionCodeForNumber
    );
    return numberDetails;
  } catch (error) {
    return {
      numberType: -1,
      errorMessage: error.message,
      isValidNumberForRegion: false
    };
  }
};

export const isMobileNumberValid = (mobileNumberString) => {
  const numberDetail = getPhoneNumberDetails(mobileNumberString);
  const { numberType, isValidNumberForRegion } = numberDetail;
  const numberIsNotFixedLine = numberType > PhoneNumberType.FIXED_LINE;
  return numberIsNotFixedLine && isValidNumberForRegion;
};

export const convertToStandardMobileNumberFormat = (mobileNumberString) => {
  if (isMobileNumberValid(mobileNumberString)) {
    const numberDetail = getPhoneNumberDetails(mobileNumberString);
    const { countryCode, number } = numberDetail;
    return `${countryCode} ${number}`;
  }
  return null;
};
