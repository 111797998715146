import { useCurrentRefinements } from 'react-instantsearch';

const isModifierClick = ({ altKey, button, ctrlKey, metaKey, shiftKey }) => {
  const isMiddleClick = button === 1;

  return Boolean(isMiddleClick || altKey || ctrlKey || metaKey || shiftKey);
};

const CurrentRefinements = (props) => {
  const { items, refine } = useCurrentRefinements(props);
  const { removeCurrentRefinement } = props;

  return (
    <div className='ais-CurrentRefinements'>
      <ul className='ais-CurrentRefinements-list !flex flex-wrap !gap-2 !mb-0'>
        {items.map(({ attribute, indexName, label, refinements }) => (
          <li
            className='ais-CurrentRefinements-item bg-white border-brand px-2 rounded-md flex flex-wrap gap-2 searchkit-selected-filters'
            key={[indexName, label].join('/')}
          >
            <span className='ais-CurrentRefinements-label font-medium text-nero text-sm'>
              {label}:
            </span>
            {refinements.map((refinement) => (
              <span
                className='ais-CurrentRefinements-category pl-1 pr-2 py-2 rounded-md flex !ml-0 text-xs font-light'
                key={refinement.label}
              >
                <span className='ais-CurrentRefinements-categoryLabel'>
                  {refinement.label}
                </span>
                <button
                  className='ais-CurrentRefinements-delete !ml-2 p-0 self-center searchkit-filter-delete-icon'
                  onClick={(event) => {
                    if (isModifierClick(event)) {
                      return;
                    }
                    removeCurrentRefinement(attribute);
                    refine(refinement);
                  }}
                  type='button'
                >
                  X
                </button>
              </span>
            ))}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CurrentRefinements;
