import { Button } from '@/components/atomic/nuclei';

const NavigateCTA = ({
  leftSideButtonProps = {},
  rightSideButtonProps = {}
}) => (
  <div
    className={`fixed bg-white z-10 bottom-0 px-6 py-3 flex gap-4 justify-end p-6 !place-content-center md:!place-content-end swipeable-footer-view md:w-1/2 border-t border-brand`}
  >
    <Button
      {...{
        className:
          'bg-[#F3F3F6] rounded text-dim-gray py-3 text-sm font-medium',
        width: 'w-40',
        ...leftSideButtonProps
      }}
    />
    <Button
      {...{
        className:
          'bg-brand-gradient rounded text-white py-3 text-sm font-medium',
        width: 'w-40',
        ...rightSideButtonProps
      }}
    />
  </div>
);

export default NavigateCTA;
