import { useRouter } from 'next/router';

import { getPageURL, PAGE_NAME } from 'services/plannerPortal.service';

const usePageExit = (pageName = PAGE_NAME.ACTION_CENTER.label) => {
  const router = useRouter();

  const handleExit = () => {
    if (window?.navigation?.canGoBack || window?.history?.length > 2) {
      router.back();
    } else {
      router.push(
        getPageURL({
          pageName
        })
      );
    }
  };

  return handleExit;
};

export default usePageExit;
