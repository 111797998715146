import Image from 'next/image';
import Link from 'next/link';
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from 'react-grid-dnd';

import {
  CartItemCTA,
  CartItemSummary,
  RackRateOfUnOrderedCartItem
} from '@/components/atomic/atoms';
import { CartItemDetail, LineItemMedia } from '@/components/atomic/molecules';
import { Button, Text, Tooltip } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';
import { getProductPartnerList } from '@/services/cart.plan.service';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const CartItemCTAs = ({
  id,
  isCartEditable,
  onClickCloneCartItem,
  setCartItemIdInViewEditMode,
  unlockCTA,
  verifyAndSetItemInRequestToDeleteMode
}) => {
  const tooltipConfigList = [
    { target: 'clone', text: 'Clone' },
    { target: 'delete', text: 'Delete' }
  ];
  return (
    <div className='w-40 self-center flex gap-3'>
      <Button
        {...{
          className:
            'bg-gradient-to-r h-8 from-light-orange via-dark-orange to-rose text-white py-2 rounded-lg text-sm font-medium self-center',
          iconGroupClassName: 'gap-0',
          iconHeight: 15,
          iconPosition: 'Left',
          iconURL: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
          iconWidth: 15,
          label: 'View',
          onClick: () => unlockCTA && setCartItemIdInViewEditMode(id),
          width: 'w-20'
        }}
      />
      <CartItemCTA
        {...{
          className: 'self-center',
          id: 'clone',
          onClick: () => onClickCloneCartItem({ cartItemId: id }),
          type: 'cloneCartItem'
        }}
      />
      <CartItemCTA
        {...{
          className: `self-center ${
            isCartEditable && unlockCTA ? '' : 'pointer-events-none opacity-40'
          }`,
          id: 'delete',
          onClick: () =>
            isCartEditable &&
            unlockCTA &&
            verifyAndSetItemInRequestToDeleteMode(id),

          type: 'delete'
        }}
      />
      <Tooltip {...{ configList: tooltipConfigList }} />
    </div>
  );
};

const PricedCartItemList = ({
  cartId,
  cartItem,
  cartItemIdInViewEditMode,
  isCartEditable,
  lastCartItem,
  onClickCloneCartItem,
  onDeleteCartItemMedia,
  resequenceCartItems,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  setShowToast,
  updateCartItemMediaSortOrder,
  userId,
  verifyAndSetItemInRequestToDeleteMode
}) => {
  const {
    derivedValues,
    id,
    itemMediaForCarousel,
    product,
    showProductMedia
  } = cartItem;
  const { unitRackPrice } = derivedValues;
  const { name, slug } = product;
  const unlockCTA = cartItemIdInViewEditMode !== id;
  const productDetailPageURL = getPageURL({
    pageName: PAGE_NAME.PRODUCT_DETAIL.label,
    pathParams: { slug }
  });
  const productDetailURL = resequenceCartItems ? '#' : productDetailPageURL;

  return (
    <div className='w-full'>
      <div
        className={`flex gap-5 text-sm font-light py-3 px-4 border-b border-l border-r ${
          lastCartItem ? 'rounded-b-lg' : ''
        } border-platinum ${
          cartItemIdInViewEditMode === id
            ? 'bg-planner-cart-head'
            : 'bg-lightgray'
        }`}
      >
        <div className='flex-1 flex gap-3 self-center'>
          {resequenceCartItems ? (
            <div className='h-10 pt-2 -ml-2'>
              <Image
                {...{
                  alt: 'cart items reorder icon',
                  height: 0,
                  src: `${staticMediaStoreBaseURL}/icons/reorder-black-icon.svg`,
                  style: { height: 30, width: 30 },
                  width: 0
                }}
              />
            </div>
          ) : (
            <LineItemMedia
              {...{
                isCartEditable,
                itemId: id,
                itemMediaForCarousel,
                itemParentId: cartId,
                onDeleteCartItemMedia,
                productName: name,
                setCartItemIdForUploadMediaModal,
                setShowToast,
                showProductMedia,
                updateCartItemMediaSortOrder,
                userId
              }}
            />
          )}
          <Link
            className='self-center'
            href={productDetailURL}
          >
            <Text
              {...{
                className: 'flex-1 flex',
                content: name
              }}
            />
          </Link>
        </div>

        <div className='w-40 self-center'>
          <RackRateOfUnOrderedCartItem {...{ product, unitRackPrice }} />
        </div>
        <CartItemSummary {...{ cartItem, isPricedCartItem: true }} />
        {!resequenceCartItems && (
          <CartItemCTAs
            {...{
              id,
              isCartEditable,
              onClickCloneCartItem,
              setCartItemIdInViewEditMode,
              unlockCTA,
              verifyAndSetItemInRequestToDeleteMode
            }}
          />
        )}
      </div>
    </div>
  );
};

const CartItemList = ({
  cartId,
  cartItemIdInViewEditMode,
  cartItemRef,
  cartItemViewEditMode,
  isCartEditable = true,
  onClickCloneCartItem,
  onDeleteCartItemMedia,
  pricedItems,
  resequenceCartItems,
  saveCartItemChanges,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  setCartItemViewEditMode,
  setShowToast,
  unSavedChangesErrorMessage,
  updateCartItemDetail,
  updateCartItemMediaSortOrder,
  userId,
  verifyAndSetItemInRequestToDeleteMode,
  viewCartItemInOpsPortal
}) => (
  <div>
    {pricedItems.map((item, index) => (
      <div key={item.id}>
        <PricedCartItemList
          {...{
            cartId,
            cartItem: item,
            cartItemIdInViewEditMode,
            isCartEditable,
            lastCartItem: pricedItems.length - 1 === index,
            onClickCloneCartItem,
            onDeleteCartItemMedia,
            resequenceCartItems,
            saveCartItemChanges,
            setCartItemIdForUploadMediaModal,
            setCartItemIdInViewEditMode,
            setShowToast,
            updateCartItemMediaSortOrder,
            userId,
            verifyAndSetItemInRequestToDeleteMode,
            viewCartItemInOpsPortal
          }}
        />

        {cartItemIdInViewEditMode === item.id && (
          <CartItemDetail
            {...{
              cartItem: item,
              cartItemRef,
              cartItemViewEditMode,
              isCartEditable,
              productPartnersDropdownList: getProductPartnerList(
                item.product.productPartners
              ),
              setCartItemIdInViewEditMode,
              setCartItemViewEditMode,
              unSavedChangesErrorMessage,
              updateCartItemDetail
            }}
          />
        )}
      </div>
    ))}
  </div>
);

const DraggableList = ({
  cartId,
  cartItemIdInViewEditMode,
  onDragCartItem,
  pricedItems,
  resequenceCartItems,
  saveCartItemChanges,
  setCartItemIdForUploadMediaModal,
  setCartItemIdInViewEditMode,
  verifyAndSetItemInRequestToDeleteMode,
  viewCartItemInOpsPortal
}) => (
  <GridContextProvider onChange={onDragCartItem}>
    <GridDropZone
      boxesPerRow={1}
      id='items'
      rowHeight={60}
      style={{ height: pricedItems.length * 60 }}
    >
      {pricedItems.map((item, index) => (
        <div key={item.id}>
          <GridItem key={item.id}>
            <PricedCartItemList
              {...{
                cartId,
                cartItem: item,
                cartItemIdInViewEditMode,
                lastCartItem: pricedItems.length - 1 === index,
                resequenceCartItems,
                saveCartItemChanges,
                setCartItemIdForUploadMediaModal,
                setCartItemIdInViewEditMode,
                verifyAndSetItemInRequestToDeleteMode,
                viewCartItemInOpsPortal
              }}
            />
          </GridItem>
        </div>
      ))}
    </GridDropZone>
  </GridContextProvider>
);

const PricedWIPCartItemOrganism = ({
  itemListComponentCommonProps,
  onClickCloneCartItem,
  pricedItems,
  setPricedItems
}) => {
  const {
    cartId,
    cartItemIdInViewEditMode,
    cartItemRef,
    cartItemViewEditMode,
    isCartEditable,
    onDeleteCartItemMedia,
    resequenceCartItems,
    saveCartItemChanges,
    setCartItemIdForUploadMediaModal,
    setCartItemIdInViewEditMode,
    setCartItemViewEditMode,
    setShowToast,
    unSavedChangesErrorMessage,
    updateCartItemDetail,
    updateCartItemMediaSortOrder,
    userId,
    verifyAndSetItemInRequestToDeleteMode,
    viewCartItemInOpsPortal
  } = itemListComponentCommonProps;
  const onDragCartItem = (sourceId, sourceIndex, targetIndex) => {
    const sortedCartItemList = swap(pricedItems, sourceIndex, targetIndex);
    setPricedItems(sortedCartItemList);
  };
  if (resequenceCartItems) {
    return (
      <DraggableList
        {...{
          cartId,
          cartItemIdInViewEditMode,
          onDragCartItem,
          pricedItems,
          resequenceCartItems,
          saveCartItemChanges,
          setCartItemIdForUploadMediaModal,
          setCartItemIdInViewEditMode,
          verifyAndSetItemInRequestToDeleteMode,
          viewCartItemInOpsPortal
        }}
      />
    );
  }

  return (
    <CartItemList
      {...{
        cartId,
        cartItemIdInViewEditMode,
        cartItemRef,
        cartItemViewEditMode,
        isCartEditable,
        onClickCloneCartItem,
        onDeleteCartItemMedia,
        pricedItems,
        resequenceCartItems,
        saveCartItemChanges,
        setCartItemIdForUploadMediaModal,
        setCartItemIdInViewEditMode,
        setCartItemViewEditMode,
        setShowToast,
        unSavedChangesErrorMessage,
        updateCartItemDetail,
        updateCartItemMediaSortOrder,
        userId,
        verifyAndSetItemInRequestToDeleteMode,
        viewCartItemInOpsPortal
      }}
    />
  );
};

export default PricedWIPCartItemOrganism;
