import {
  Button,
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import { useUIContext } from '@/components/context/UiContext/ManagedUiContext';
import { staticMediaStoreBaseURL } from '@/config/common';
import { parseFormatPriceNumberValueFromAPI } from '@/lib/numberStringUtils';
import { addToCart } from '@/services/product.service';

const ProductCardSummary = ({
  activeModalsConfig,
  discount,
  name,
  partnerName,
  price,
  priceBasedOnProductTypeMap,
  priceId,
  productId,
  productPartners,
  setActiveModal,
  setShowToast,
  shortDescription,
  showDiscount,
  type,
  unitPartnerPrice
}) => {
  const { activePlannerCart, updateActivePlannerCart } = useUIContext();

  return (
    <div className='flex flex-col p-3'>
      <TextKeyValuePair
        {...{
          className: 'flex-col !gap-1',
          label: name,
          labelClass: 'font-medium max-w-80 truncate',
          spaceTop: '',
          value: shortDescription,
          valueClassName:
            'text-xs text-brand mb-1 font-medium max-w-80 truncate'
        }}
      />
      <div className='flex gap-2 font-medium'>
        <Text
          {...{
            className: 'text-nero text-sm',
            content: priceBasedOnProductTypeMap[type]
          }}
        />
        {showDiscount && (
          <TextKeyValuePair
            {...{
              className: '',
              label: `${parseFormatPriceNumberValueFromAPI(price)} AED`,
              labelClass: 'line-through text-xs text-dim-gray self-center',
              value: `${discount}% off`,
              valueClassName: 'text-green text-md font-medium'
            }}
          />
        )}
      </div>
      <div className='my-3 border-t' />
      <div className='flex flex-col gap-2 text-sm text-dim-gray font-medium'>
        <div className='flex gap-1'>
          <TextWithIcon
            {...{
              alt: 'partner icon',
              className: '',
              icon: 'partner-brand-icon.svg',
              iconStyle: 'rounded-lg',
              label: partnerName,
              labelStyle: 'max-w-44 truncate'
            }}
          />
          {productPartners.length > 1 && (
            <Text
              {...{
                className:
                  'text-brand bg-base-white shadow text-xs font-medium px-2 py-[2px] self-center flex ml-2 rounded',
                content: ` +${productPartners.length - 1} more`
              }}
            />
          )}
        </div>
        <TextWithIcon
          {...{
            alt: 'price-icon',
            className: 'flex gap-1',
            icon: 'price-icon.svg',
            iconHeight: 18,
            iconStyle: 'rounded-lg',
            iconWidth: 18,
            label: `AED ${parseFormatPriceNumberValueFromAPI(unitPartnerPrice)}`,
            labelStyle: 'max-w-72 truncate'
          }}
        />
        <Button
          {...{
            className:
              'bg-brand-gradient text-white font-medium py-2 rounded text-sm add-to-cart-btn text-center',
            iconGroupClassName: 'gap-2',
            iconHeight: 18,
            iconPosition: 'Left',
            iconURL: `${staticMediaStoreBaseURL}/icons/cart-white-icon.svg`,
            iconWidth: 18,
            label: 'Add to Cart',
            onClick: (e) => {
              e.stopPropagation();
              const productDetail = {
                entityId: productId,
                entityType: type,
                quantity: 1,
                priceId
              };
              if (activePlannerCart) {
                addToCart({
                  activePlannerCart,
                  productDetail,
                  setShowToast,
                  updateActivePlannerCart
                });
              } else {
                setActiveModal({
                  key: activeModalsConfig.ADD_TO_CART.key,
                  value: productDetail
                });
              }
            }
          }}
        />
      </div>
    </div>
  );
};

export default ProductCardSummary;
