import { sendGAEvent, sendGTMEvent } from '@next/third-parties/google';

import { isProduction, PRODUCT_TYPE } from '@/config/common';
import { PLANNER_PORTAL_URL } from '@/services/connections.service';
import { COOKIE_MAP, getCookie } from '@/services/cookie.service';
import { getPlannerId } from '@/services/identity.service';
import { PAGE_NAME } from '@/services/plannerPortal.service';
import { formatToTwoDecimalString } from 'lib/numberStringUtils';

const debugDataLayer = !isProduction;

function log(...arr) {
  // eslint-disable-next-line no-console
  debugDataLayer && console.log(...arr);
}

const unAvailableValuePlaceholder = '';
const CURRENCY = 'AED';

const GTM_EVENTS = {
  ADD_TO_CART: 'add_to_cart',
  BEGIN_DEFAULT_CART_CHECKOUT: 'begin_checkout',
  GENERIC_CTA_CLICK: 'cta_click',
  PAGE_VIEW: 'page_view_custom',
  PROCEED_TO_PAYMENT_CLICK: 'add_payment_info',
  REGISTER_PURCHASE_COMPLETION: 'purchase',
  REMOVE_FROM_CART: 'remove_from_cart'
};

const getGoogleClientId = () => {
  const gaCookie = getCookie({ name: COOKIE_MAP.GA }) || '';
  return gaCookie.substring(6);
};

const getAllUserIds = () => ({
  client_id: getGoogleClientId(),
  planner_id: getPlannerId()
});

const captureEvent = (eventObject = {}) => {
  const eventObjectWithIds = {
    ...getAllUserIds(),
    ...eventObject
  };
  sendGTMEvent(eventObjectWithIds);
};

const getPageName = (urlPathQueryFragment = '') => {
  const pageNameObjectList = Object.values(PAGE_NAME);
  const urlPath = new URL(`${PLANNER_PORTAL_URL}${urlPathQueryFragment}`)
    .pathname;
  const pageNameObject = pageNameObjectList.find(({ regex }) =>
    regex.test(urlPath)
  );
  return pageNameObject?.label || 'unknown';
};

export const captureGTMEventPageView = (urlPathQueryFragment) => {
  log('captureGTMEventPageView', {
    event: GTM_EVENTS.PAGE_VIEW,
    page: urlPathQueryFragment,
    page_name: getPageName(urlPathQueryFragment)
  });
  sendGAEvent({
    event: GTM_EVENTS.PAGE_VIEW,
    page: urlPathQueryFragment,
    page_name: getPageName(urlPathQueryFragment)
  });
};

const cartAndOrderItemDefaultValues = {
  affiliation: 'Website',
  coupon: unAvailableValuePlaceholder,
  discount: 0,
  index: 0,
  item_brand: unAvailableValuePlaceholder,
  item_id: 'item-id-not-available',
  item_list_id: unAvailableValuePlaceholder,
  item_name: 'item-name-not-available',
  item_variant: unAvailableValuePlaceholder,
  location_id: unAvailableValuePlaceholder,
  price: 0,
  quantity: 1
};

const assignedToCategoriesWrapper = (assignedToCategories) => {
  if (!assignedToCategories || assignedToCategories.length === 0) return {};
  return {
    item_category: assignedToCategories[0]
      ? assignedToCategories[0].toLowerCase()
      : null,
    item_category2: assignedToCategories[1]
      ? assignedToCategories[1].toLowerCase()
      : null,
    item_category3: assignedToCategories[2]
      ? assignedToCategories[2].toLowerCase()
      : null,
    item_list_name: assignedToCategories[2]
      ? assignedToCategories[2].toLowerCase()
      : null
  };
};

const categoryWrapper = (categories) => {
  if (!categories) return {};
  const category = categories[0];
  return {
    item_category: category?.name.toLowerCase() || null,
    item_category2: category.parentCategory?.name.toLowerCase() || null,
    item_category3:
      category.parentCategory.parentCategory?.name.toLowerCase() || null,
    item_list_name:
      category.parentCategory.parentCategory?.name.toLowerCase() || null
  };
};

const checkoutAndPurchasedOrderItems = (orderItems) =>
  orderItems.map(
    (
      {
        categories,
        entityId,
        product,
        quantity,
        sellingPrice,
        unitListedDiscount
      },
      index
    ) => {
      const discountInputForFormating = {
        value: (unitListedDiscount || 0) * quantity
      };
      return {
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        discount: unitListedDiscount
          ? formatToTwoDecimalString(discountInputForFormating)
          : 0,
        index,
        item_id: entityId,
        item_name: product.name,
        price: formatToTwoDecimalString({ value: sellingPrice }),
        quantity
      };
    }
  );

const checkoutAndPurchasedCartItems = (cartItems) =>
  cartItems
    .filter(({ derivedValues: { isValidForCheckout } }) => isValidForCheckout)
    .map(
      (
        {
          id,
          derivedValues: { unitSellingPrice },
          product: { categories, name },
          quantity,
          unitListedDiscount
        },
        index
      ) => {
        const discountInputForFormating = {
          value: (unitListedDiscount || 0) * quantity
        };
        return {
          ...cartAndOrderItemDefaultValues,
          ...categoryWrapper(categories),
          discount: unitListedDiscount
            ? formatToTwoDecimalString(discountInputForFormating)
            : 0,
          item_id: id,
          index,
          item_name: name,
          price: formatToTwoDecimalString({ value: unitSellingPrice || 0 }),
          quantity
        };
      }
    );

export const captureGTMEventPurchaseCompletion = ({
  orderId,
  orderItems = [],
  orderTotal,
  paymentFlowSource
}) => {
  const eCommerce = {
    currency: CURRENCY,
    items:
      orderItems.length > 0
        ? checkoutAndPurchasedOrderItems(orderItems)
        : [{ ...cartAndOrderItemDefaultValues, price: orderTotal }],
    transaction_id: orderId,
    value: formatToTwoDecimalString({ value: orderTotal })
  };
  captureEvent({
    ecommerce: eCommerce,
    event: GTM_EVENTS.REGISTER_PURCHASE_COMPLETION,
    payment_flow_source: paymentFlowSource
  });
};

const captureGTMEventAddToCart = ({
  assignedToCategories,
  availableDiscount,
  categories,
  id,
  name,
  pageName,
  price,
  quantity,
  type = PRODUCT_TYPE.CART
}) => {
  const productPrice = {
    Cart: formatToTwoDecimalString({ value: price }),
    Quote: 0
  };

  const ecommerce = {
    currency: CURRENCY,
    items: [
      {
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        ...assignedToCategoriesWrapper(assignedToCategories),
        coupon: unAvailableValuePlaceholder,
        discount: availableDiscount?.value
          ? `${formatToTwoDecimalString({ value: availableDiscount.value })}%`
          : 0,
        index: 0,
        item_id: id,
        item_name: name,
        price: productPrice[type],
        quantity
      }
    ],
    value: productPrice[type]
  };
  captureEvent({
    ecommerce,
    event: GTM_EVENTS.ADD_TO_CART,
    page_name: pageName
  });
};

export const captureGTMEventRemoveFromCart = ({
  availableDiscount,
  categories,
  id,
  name,
  pageName,
  price,
  quantity,
  type = PRODUCT_TYPE.CART
}) => {
  const productPrice = {
    Cart: formatToTwoDecimalString({ value: price }),
    Quote: 0
  };

  const ecommerce = {
    currency: CURRENCY,
    items: [
      {
        ...cartAndOrderItemDefaultValues,
        ...categoryWrapper(categories),
        discount: availableDiscount?.value
          ? `${formatToTwoDecimalString({ value: availableDiscount.value })}%`
          : 0,
        index: 0,
        item_id: id,
        item_name: name,
        price: productPrice[type],
        quantity
      }
    ],
    value: productPrice[type]
  };

  captureEvent({
    ecommerce,
    event: GTM_EVENTS.REMOVE_FROM_CART,
    page_name: pageName
  });
};

export const captureGTMEventBeginCheckout = ({
  code = unAvailableValuePlaceholder,
  itemsList,
  orderTotal,
  pageName
}) => {
  const ecommerce = {
    coupon: code || unAvailableValuePlaceholder,
    currency: CURRENCY,
    items: checkoutAndPurchasedCartItems(itemsList),
    value: formatToTwoDecimalString({ value: orderTotal })
  };
  captureEvent({
    ecommerce,
    event: GTM_EVENTS.BEGIN_DEFAULT_CART_CHECKOUT,
    pageName
  });
};

export const captureGTMEventProceedToPayment = ({
  cartItems,
  code,
  orderTotal,
  paymentType
}) => {
  const ecommerce = {
    coupon: code || unAvailableValuePlaceholder,
    currency: CURRENCY,
    items: checkoutAndPurchasedCartItems(cartItems),
    payment_type: paymentType,
    shipping_tier: 'Ground',
    value: formatToTwoDecimalString({ value: orderTotal })
  };

  captureEvent({ ecommerce, event: GTM_EVENTS.PROCEED_TO_PAYMENT_CLICK });
};

export const updateGTMEventCartQuantity = ({
  availableDiscount,
  currentQuantity,
  id,
  minPrice,
  name,
  oldQuantity,
  pageName,
  price,
  type
}) => {
  const isQuantityChanged = oldQuantity !== currentQuantity;

  if (isQuantityChanged) {
    const isQuantityIncreased = oldQuantity < currentQuantity;
    const captureGTMEventClick = isQuantityIncreased
      ? captureGTMEventAddToCart
      : captureGTMEventRemoveFromCart;

    captureGTMEventClick({
      availableDiscount,
      id,
      minPrice,
      name,
      pageName,
      price,
      quantity: currentQuantity,
      type
    });
  }
};
