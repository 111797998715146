import _ from 'lodash';
import { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';

import {
  HostCreditEntityProfileStepOne,
  Loader,
  StepFormHeader,
  ViewEditCTAPair
} from '@/components/atomic/atoms';
import {
  HostCreditEntityProfileStepThree,
  HostCreditEntityProfileStepTwo
} from '@/components/atomic/molecules';
import {
  CREDIT_REQUEST_STATUS,
  REVIEW_CREDIT_REQUEST_CTA_PAIR
} from '@/config/hostCreditEntity';
import usePageExit from '@/hooks/usePageExit';
import useYupValidator from '@/hooks/useYupValidator';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import { isEmptyOrNull } from '@/lib/utils';
import {
  hostCreditEntityProfileStepFieldErrorConfig,
  hostCreditEntityProfileTabs,
  isPlannerCreditFacilityReviewer,
  updateHostCreditEntity,
  updateHostCreditEntityCreditRequest,
  validateApprovedCreditLimit,
  validateReviewerNotes
} from '@/services/hostCreditEntity.service';
import { getPlannerId } from '@/services/identity.service';

const HostProfileHeader = ({
  additionalOperationOnTabClick,
  errors,
  fieldsErrorConfig,
  formActiveStepId,
  onClickExit,
  setFormActiveStepId,
  showCloseIcon,
  tabListWithIcons
}) => (
  <StepFormHeader
    {...{
      additionalOperationOnTabClick,
      errors,
      fieldsErrorConfig,
      formActiveStepId,
      onClickExit,
      setFormActiveStepId,
      showCloseIcon,
      tabList: tabListWithIcons
    }}
  />
);

const ViewEditHostCreditEntityProfileOrganism = ({
  hostCreditEntityCreditRequests,
  hostCreditEntityId,
  hostCreditEntityProfileDetails: { hostCreditEntity },
  opsUserList,
  setHostCreditEntityCreditRequests,
  setHostCreditEntityProfileDetails,
  setShowToast,
  showToast
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [formActiveStepId, setFormActiveStepId] = useState(
    Object.values(hostCreditEntityProfileTabs)[0].id
  );
  const [loading, setLoading] = useState(null);

  const pendingCreditRequest = hostCreditEntityCreditRequests.find(
    ({ status }) => status === CREDIT_REQUEST_STATUS.REQUESTED.value
  );

  const approvedCreditRequest = hostCreditEntityCreditRequests.find(
    ({ status }) => status === CREDIT_REQUEST_STATUS.APPROVED.value
  );

  const historicalCreditRequests = hostCreditEntityCreditRequests.filter(
    ({ status }) =>
      status !== CREDIT_REQUEST_STATUS.REQUESTED.value &&
      status !== CREDIT_REQUEST_STATUS.APPROVED.value
  );

  const noActiveCreditRequestExist =
    isEmptyOrNull(pendingCreditRequest) &&
    isEmptyOrNull(approvedCreditRequest);

  const isActiveCreditTab =
    formActiveStepId === hostCreditEntityProfileTabs.ACTIVE_CREDIT.id;

  const isEditEnabled =
    isPlannerCreditFacilityReviewer &&
    isActiveCreditTab &&
    !noActiveCreditRequestExist;

  const yupResolver = useYupValidator(
    yup.object().shape({
      approvedCreditLimit: yup
        .string()
        .test(
          'is-required-when-approved',
          'Approved Credit Limit must be greater than 0',
          (
            value,
            {
              parent: {
                approvedCreditLimit,
                selectedReviewActionForCreditRequest
              }
            }
          ) =>
            validateApprovedCreditLimit({
              approvedCreditLimit,
              selectedReviewActionForCreditRequest
            })
        ),
      reviewerNotes: yup
        .string()
        .nullable()
        .test(
          'is-required-when-rejected',
          'Notes is required',
          (
            value,
            { parent: { reviewerNotes, selectedReviewActionForCreditRequest } }
          ) =>
            validateReviewerNotes({
              reviewerNotes,
              selectedReviewActionForCreditRequest
            })
        )
    })
  );

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch
  } = useForm({
    resolver: yupResolver,
    values: {
      ...hostCreditEntity,
      ...pendingCreditRequest,
      approvedCreditLimit: parseFormatPriceNumberValueFromAPI(
        pendingCreditRequest?.requestedCreditLimit
      ),
      creditFacilityEnabled: Boolean(hostCreditEntity?.creditFacilityEnabled),
      formattedRequestedCreditLimit: convertToCurrencyFormat({
        value: parseFormatPriceNumberValueFromAPI(
          pendingCreditRequest?.requestedCreditLimit
        ),
        withCurrencyPrefix: false
      }),
      reviewedBy: getPlannerId()
    }
  });

  const { errors } = useFormState({
    control
  });

  const onClickExit = usePageExit();

  const onSubmit = ({
    approvedCreditLimit,
    creditFacilityEnabled,
    id,
    reviewerNotes,
    selectedReviewActionForCreditRequest: status
  }) => {
    const pendingToRejectCreditRequestPayload = { reviewerNotes, status };

    const pendingToApproveCreditRequestPayload = {
      approvedCreditLimit: parsePriceValueForAPI(approvedCreditLimit),
      reviewerNotes,
      status
    };

    if (!isEmptyOrNull(pendingCreditRequest)) {
      const payload =
        status === REVIEW_CREDIT_REQUEST_CTA_PAIR.APPROVE.value
          ? pendingToApproveCreditRequestPayload
          : pendingToRejectCreditRequestPayload;
      status &&
        updateHostCreditEntityCreditRequest({
          hostCreditEntityId,
          id,
          payload,
          setHostCreditEntityCreditRequests,
          setHostCreditEntityProfileDetails,
          setIsEditable,
          setLoading,
          setShowToast
        });
    } else if (!isEmptyOrNull(approvedCreditRequest)) {
      updateHostCreditEntity({
        creditFacilityEnabled,
        hostCreditEntityId,
        setHostCreditEntityProfileDetails,
        setIsEditable,
        setLoading,
        setShowToast
      });
    }
  };

  const onSubmitHandler = isEditable
    ? handleSubmit(onSubmit)
    : () => setIsEditable(isEditEnabled);

  const notificationConfig = {
    errors,
    toastConfig: {
      ...showToast,
      show: showToast.show || !_.isEmpty(errors)
    }
  };

  const tabListWithIcons = Object.values(hostCreditEntityProfileTabs).map(
    (tab) => {
      const showIcon = tab.icon && !isEmptyOrNull(pendingCreditRequest);
      return {
        ...tab,
        icon: showIcon ? tab.icon : null
      };
    }
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='flex-1 px-5 md:ml-[30rem] pl-10 md:pr-10 relative'>
      <div
        id={hostCreditEntityProfileTabs.PROFILE.id}
        className='bg-white mb-7 px-6 pb-10'
      >
        <HostProfileHeader
          {...{
            additionalOperationOnTabClick: () => setIsEditable(false),
            errors,
            fieldsErrorConfig: hostCreditEntityProfileStepFieldErrorConfig,
            formActiveStepId,
            onClickExit,
            setFormActiveStepId,
            showCloseIcon: !isEditable,
            tabListWithIcons
          }}
        />
        <HostCreditEntityProfileStepOne
          {...{
            clearErrors,
            errors,
            formActiveStepId,
            getValues,
            isEditable,
            opsUserList,
            register,
            setValue
          }}
        />

        <HostCreditEntityProfileStepTwo
          {...{
            approvedCreditRequest,
            clearErrors,
            errors,
            formActiveStepId,
            isEditable,
            pendingCreditRequest,
            register,
            setValue,
            showNoActiveCreditRequestBanner: noActiveCreditRequestExist,
            watch
          }}
        />
        <HostCreditEntityProfileStepThree
          {...{ formActiveStepId, historicalCreditRequests }}
        />
        <ViewEditCTAPair
          {...{
            backward: {
              onClick: () => {
                if (isEditable) {
                  setIsEditable(false);
                  clearErrors();
                  reset();
                } else {
                  onClickExit();
                }
              }
            },
            forward: {
              disabled: !isEditEnabled,
              onClick: onSubmitHandler
            },
            notificationConfig,
            type: isEditable ? 'editCTAPair' : 'viewCTAPair'
          }}
        />
      </div>
    </div>
  );
};

export default ViewEditHostCreditEntityProfileOrganism;
