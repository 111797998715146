import Image from 'next/image';
import { useState } from 'react';

import {
  EventOrderBanner,
  EventOrderDetails
} from '@/components/atomic/atoms';
import { Text, TextWithIcon } from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const BookOrderSuccessBanner = () => (
  <div className='bg-greenlight flex gap-1 rounded-lg flex-col items-center py-4 text-green'>
    <TextWithIcon
      {...{
        className: 'shadown-none',
        icon: 'check-fill-green-icon.svg',
        iconHeight: 24,
        iconWidth: 24,
        label: 'Woohoo! Your Order is Booked 🎉',
        labelStyle: 'text-2xl font-semibold'
      }}
    />
    <Text
      {...{
        className: 'text-base font-medium',
        content: `Here's a quick look at what you’ve ordered`
      }}
    />
  </div>
);

const EventOrderInfo = ({
  orderBannerDetail,
  showBookOrderSuccessBanner,
  userEventDetails
}) => {
  const [showEventOrderDetails, setShowEventOrderDetails] = useState(true);
  return (
    <>
      {showBookOrderSuccessBanner && <BookOrderSuccessBanner />}
      <div className='mb-6 mt-4 relative'>
        <div
          className='cursor-pointer'
          onClick={() => setShowEventOrderDetails(!showEventOrderDetails)}
        >
          <EventOrderBanner {...{ orderBannerDetail }} />
          <div className='absolute -bottom-5 right-4'>
            <Image
              alt='down angle'
              className={`cursor-pointer transform ${
                showEventOrderDetails ? 'rotate-180' : 'rotate-0'
              }`}
              height={0}
              priority={true}
              src={`${staticMediaStoreBaseURL}/icons/down-angle-circle-icon.svg`}
              style={{
                height: 40,
                width: 40
              }}
              width={0}
            />
          </div>
        </div>

        {showEventOrderDetails && (
          <EventOrderDetails {...{ userEventDetails }} />
        )}
      </div>
    </>
  );
};

export default EventOrderInfo;
