import Link from 'next/link';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import { DatePicker } from '@/components/atomic/atoms';
import {
  Button,
  CloseIcon,
  InputField,
  SelectFormInput,
  Text,
  TextKeyValuePair,
  TextWithIcon
} from '@/components/atomic/nuclei';
import {
  ALLOCATION_STATUS,
  CART_STATUS,
  USER_EVENT_STAGE
} from '@/config/common';
import useYupValidator from '@/hooks/useYupValidator';
import {
  convertToShortMonthDateFormat,
  getFormattedDate,
  parseDateWithMoment,
  subtractDaysFromDate
} from '@/lib/time';
import {
  updateCart,
  validateMandatoryFieldsForEPICEnhanced,
  validateMandatoryFieldsForEPICGenerated
} from '@/services/userEventCartList.service';

const CartStatusChangeValidationError = () => (
  <div className='add-new-card-info border border-neutral rounded-lg p-8'>
    <TextKeyValuePair
      {...{
        className: 'flex flex-col !gap-1 items-center',
        label: 'Your cart is not ready to Collaborate!',
        labelClass: 'text-brand text-base font-semibold',
        value: `💡Pro-tips to collaborate the cart`,
        valueClassName: 'text-brand text-xs font-medium'
      }}
    />
    <Text
      {...{
        className: 'text-sm font-medium mt-2',
        content: '1. Event stage is set to'
      }}
    />
    <div className='flex items-center gap-3 mt-2'>
      <TextWithIcon
        {...{
          className: '',
          iconSuffix: {
            alt: 'right arrow',
            icon: 'arrow-right.svg',
            iconHeight: 20,
            iconWidth: 20
          },
          label: USER_EVENT_STAGE.EVENT_ASSIGNED.label,
          labelStyle:
            'bg-white py-1 text-sm font-medium text-brand px-2 rounded italic'
        }}
      />
      <TextWithIcon
        {...{
          className: 'relative',
          iconSuffix: {
            alt: 'check fill',
            icon: 'check-fill-green-icon.svg',
            iconHeight: 12,
            iconStyle: 'absolute -right-1 -top-1',
            iconWidth: 12
          },
          label: USER_EVENT_STAGE.EVENT_ACCEPTED.label,
          labelStyle:
            'bg-white py-1 text-sm font-medium text-brand px-2 rounded italic'
        }}
      />
    </div>
    <Text
      {...{
        className: 'mt-4 text-sm font-medium',
        content:
          '2. Ensure all fields in the Event Profile - Deep Dive section are filled in'
      }}
    />
    <div className='flex gap-2 mt-2 text-sm font-medium text-brand'>
      <Text {...{ content: '🪄 To check out the video guide' }} />
      <Link
        className='cursor-pointer'
        href='https://www.loom.com/share/fe091fac4afb4cc495b3f4e37eed5cba?sid=8cfc4c80-d85f-4fff-a2f8-4e54e1b0bb26'
        target='_blank'
      >
        <Text {...{ className: 'text-blue', content: 'Click here' }} />
      </Link>
    </div>
  </div>
);

const ActionButton = ({ buttonName, className, disabled, onClick }) => (
  <Button
    {...{
      className: `py-3 px-2 text-sm font-medium self-end rounded-lg ${className}`,
      disabled,
      label: buttonName,
      onClick
    }}
  />
);

const ActionButtonPair = ({ actionButtonPairList }) => (
  <div className='flex gap-2 mt-4 justify-between'>
    {actionButtonPairList.map(
      ({ buttonName, className, disabled = false, onClick }, id) => (
        <ActionButton
          key={id}
          {...{
            buttonName,
            className,
            disabled,
            onClick
          }}
        />
      )
    )}
  </div>
);

const DatePickerField = ({
  className = 'flex w-1/2 gap-2 flex-col mb-4',
  dbName,
  errors,
  label,
  iconURL,
  minDate,
  selectedDate,
  setSelectedDate,
  setValue
}) => (
  <div className={className}>
    <Text
      className='text-nero text-base'
      content={label}
    />
    <DatePicker
      {...{
        dbName,
        className: 'h-12 w-full pl-10',
        errors,
        iconHeight: 24,
        iconURL,
        iconWidth: 24,
        minDate,
        selectedDate,
        setSelectedDate,
        setValue
      }}
    />
  </div>
);

const CartSummaryModal = ({
  cartSummary,
  eventDate,
  isPlannerAssigned,
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventId,
  userEventInformation,
  userId
}) => {
  const { cartName, cartNumber, deliveryDate, pickupDate, status, updatedAt } =
    cartSummary || {};

  const isCartInDraftState = status === CART_STATUS.DRAFT.value;

  const { userEventDetails: [{ allocationStatus } = {}] = [] } =
    userEventInformation;
  const [selectedDate, setSelectedDate] = useState(null);
  const [cartStatusChangeValidationError, setCartStatusChangeValidationError] =
    useState(null);

  const isNotAbleToChangeCartStatus =
    status === CART_STATUS.COLLABORATE.value ||
    cartStatusChangeValidationError;

  const dateOfEvent = eventDate && parseDateWithMoment(eventDate);
  const twoDaysBeforeDateOfEvent = subtractDaysFromDate({
    date: eventDate,
    daysToSubtract: 2
  });

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm({
    resolver: useYupValidator(
      yup.object().shape({
        cartName: yup
          .string()
          .trim()
          .min(4, 'Cart Name must be at least 4 characters.')
          .max(128, 'Cart Name has a maximum limit of 128 characters.')
          .matches(/^[^\uD800-\uDFFF]+$/g, 'Emojis are not allowed')
          .required('Cart Name is required.'),
        pickupDate: yup
          .date()
          .min(
            yup.ref('deliveryDate'),
            'Pickup Date must be greater than or equal to Delivery Date.'
          )
          .nullable()
      })
    ),
    values: {
      ...cartSummary,
      deliveryDate: deliveryDate ? parseDateWithMoment(deliveryDate) : null,
      pickupDate: pickupDate ? parseDateWithMoment(pickupDate) : null
    }
  });

  const updateCartDetailOnSave = ({
    pickupDate: updatedPickupDate,
    deliveryDate: updatedDeliveryDate,
    ...otherData
  }) => {
    const pickupDateInputForFormating = {
      date: updatedPickupDate,
      format: 'YYYY-MM-DD'
    };
    const deliveryDateInputForFormating = {
      date: updatedDeliveryDate,
      format: 'YYYY-MM-DD'
    };
    updateCart({
      setLoading,
      setShowToast,
      setUserEventCartDetails,
      updateCartDetail: {
        data: {
          pickupDate: updatedPickupDate
            ? getFormattedDate(pickupDateInputForFormating)
            : null,
          deliveryDate: updatedDeliveryDate
            ? getFormattedDate(deliveryDateInputForFormating)
            : null,
          ...otherData
        }
      },
      userId,
      userEventId
    });
    setEditCartSummaryModal({ showModal: false, cartSummary: null });
  };

  const statusOptions = [
    {
      id: CART_STATUS.DRAFT.value,
      name: CART_STATUS.DRAFT.label
    },
    {
      id: CART_STATUS.COLLABORATE.value,
      name: CART_STATUS.COLLABORATE.label
    }
  ];

  const actionButtonPairList = [
    {
      buttonName: 'Cancel',
      className: 'bg-gray text-black',
      onClick: () =>
        setEditCartSummaryModal({ showModal: false, cartSummary: null })
    },
    {
      buttonName: 'Save',
      className: 'bg-brand-gradient text-white',
      onClick: handleSubmit(updateCartDetailOnSave)
    }
  ];

  const datePickerFields = [
    {
      dbName: 'deliveryDate',
      iconURL: 'delivery-truck.svg',
      label: 'Delivery Date',
      minDate: twoDaysBeforeDateOfEvent,
      selectDate: getValues('deliveryDate'),
      selectedDate,
      value: deliveryDate
    },
    {
      dbName: 'pickupDate',
      iconURL: 'pickup-truck.svg',
      label: 'Pickup Date',
      minDate: dateOfEvent,
      selectDate: getValues('pickupDate'),
      selectedDate,
      value: pickupDate
    }
  ];

  const canUserChangeCartStatus = () => {
    const isEventAccepted =
      allocationStatus === ALLOCATION_STATUS.EVENT_ACCEPTED.value;
    const hasValidEPICFields =
      validateMandatoryFieldsForEPICGenerated(userEventInformation) &&
      validateMandatoryFieldsForEPICEnhanced(userEventInformation);

    setCartStatusChangeValidationError(
      !isEventAccepted || !hasValidEPICFields || !isPlannerAssigned
    );
  };

  useEffect(() => {
    canUserChangeCartStatus();
  }, []);

  return (
    <div className='flex gap-5 justify-center h-screen mx-auto text-sm font-medium'>
      <div className='self-center w-41 z-20 bg-white rounded-lg px-8 py-6'>
        <CloseIcon
          {...{
            onClick: () =>
              setEditCartSummaryModal({ showModal: false, cartSummary: null })
          }}
        />
        <Text
          {...{
            className: 'my-4 text-lg font-medium text-center',
            content: 'Cart Details'
          }}
        />
        <div className='flex items-center justify-between mt-4'>
          <TextWithIcon
            {...{
              className:
                'text-lg font-medium text-center border border-neutral rounded py-1 px-2 bg-[#FCFCFC]',
              icon: 'cart-brand-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              labelStyle: 'text-sm text-dim-gray',
              label: `# ${cartNumber}`
            }}
          />
          <TextWithIcon
            {...{
              className:
                'text-lg font-medium text-center border border-neutral rounded py-1 px-2 bg-[#FCFCFC]',
              icon: 'calendar-brand-icon.svg',
              iconHeight: 20,
              iconWidth: 20,
              label: convertToShortMonthDateFormat(updatedAt),
              labelStyle: 'text-sm text-dim-gray'
            }}
          />
        </div>
        <div className='flex flex-col gap-4 mt-4'>
          <InputField
            {...{
              className: 'pl-10 text-base font-medium rounded-lg mt-2',
              dbName: 'cartName',
              defaultValue: cartName,
              errors,
              iconURL: 'cart-brand-icon.svg',
              label: 'Cart Name',
              labelClass: 'text-base font-medium',
              register: register('cartName')
            }}
          />

          <div className='flex gap-2 flex-col'>
            <SelectFormInput
              {...{
                clearErrors,
                containerClassName:
                  'relative multi-select-epm epm-dropdown border border-platinum rounded-lg',
                control,
                dbName: 'status',
                disabled: isNotAbleToChangeCartStatus,
                errors,
                getValues,
                icon: { url: 'solution-design-icon-red.svg' },
                isClearable: false,
                label: 'Status',
                name: 'status',
                placeholderLabel: 'Status',
                register,
                setValue,
                showIcon: true,
                showLabel: true,
                values: statusOptions
              }}
            />
          </div>
          <div className='flex gap-4 justify-between'>
            {datePickerFields.map(
              (
                { dbName, iconURL, label, minDate, selectDate, value },
                index
              ) => (
                <DatePickerField
                  key={index}
                  {...{
                    dbName,
                    errors,
                    iconURL,
                    label,
                    minDate,
                    selectedDate: selectDate,
                    setSelectedDate,
                    setValue,
                    value
                  }}
                />
              )
            )}
          </div>
        </div>
        {cartStatusChangeValidationError ? (
          <CartStatusChangeValidationError />
        ) : (
          <TextWithIcon
            {...{
              className:
                'text-lg font-medium text-center py-3 bg-[#EAF3EB] justify-center mt-4 rounded',
              icon: 'checked-green.svg',
              iconHeight: 12,
              iconWidth: 12,
              label:
                'Your cart is greenlit! Time to share with your client. Collaborate now.',
              labelStyle: 'text-sm text-[#29823B]',
              show: isCartInDraftState
            }}
          />
        )}
        <ActionButtonPair {...{ actionButtonPairList }} />
      </div>
    </div>
  );
};

const EditableCartSummaryModal = ({
  editCartSummaryModal: { showModal, cartSummary },
  setEditCartSummaryModal,
  setLoading,
  setShowToast,
  setUserEventCartDetails,
  userEventInformation
}) => {
  const {
    id: userEventId,
    userEventDetails: [{ eventDate = '', opsUser } = {}] = [],
    userId,
    zendeskTicketId
  } = userEventInformation;

  const isZendeskIdAgentNameAvailable = Boolean(
    opsUser?.name && zendeskTicketId
  );

  const closeEditCartSummaryModal = () =>
    setEditCartSummaryModal({ showModal: false, cartSummary: null });

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={showModal}
      size='lg'
      toggle={closeEditCartSummaryModal}
    >
      <ModalBody>
        <CartSummaryModal
          {...{
            cartSummary,
            eventDate,
            isPlannerAssigned: Boolean(opsUser?.name),
            isZendeskIdAgentNameAvailable,
            setEditCartSummaryModal,
            setLoading,
            setShowToast,
            setUserEventCartDetails,
            userEventId,
            userEventInformation,
            userId
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default EditableCartSummaryModal;
