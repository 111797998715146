import Image from 'next/image';
import PropTypes from 'prop-types';

const iconPositions = { left: 'Left', right: 'Right' };

const Button = ({
  alt = 'cart-bag',
  label,
  className,
  iconGroupClassName,
  iconHeight = 20,
  iconPosition = null,
  iconURL,
  iconWidth = 20,
  imageClassName,
  width = 'w-full',
  ...rest
}) => (
  <button
    className={`${className} flex items-center justify-center cursor-pointer hover:opacity-90 hover:no-underline focus:outline-none focus:ring-0 ${width}`}
    {...rest}
  >
    <div
      className={`flex items-center ${iconGroupClassName} ${
        iconPosition === 'Right' &&
        'flex-row-reverse space-x-2 space-x-reverse'
      } ${iconPosition === 'Left' && 'flex-row space-x-2'} `}
    >
      {iconURL && iconPosition && (
        <Image
          {...{
            alt,
            className: imageClassName,
            height: 0,
            src: iconURL,
            style: {
              height: iconHeight,
              width: iconWidth
            },
            width: 0
          }}
        />
      )}
      <span className='flex justify-center items-center self-center'>
        {label}
      </span>
    </div>
  </button>
);

export default Button;

Button.propTypes = {
  alt: PropTypes.string,
  label: PropTypes.any.isRequired,
  className: PropTypes.string,
  iconGroupClassName: PropTypes.string,
  iconHeight: PropTypes.number,
  iconPosition: PropTypes.oneOf(Object.values(iconPositions)),
  iconURL: PropTypes.string,
  iconWidth: PropTypes.number,
  imageClassName: PropTypes.string,
  width: PropTypes.string
};
