import { useSwipeable } from 'react-swipeable';

const handleSwipe = ({
  currentCardIndex,
  direction,
  eventCount,
  setCurrentCardIndex
}) => {
  if (direction === 'LEFT' && currentCardIndex < eventCount - 1) {
    setCurrentCardIndex(currentCardIndex + 1);
  } else if (direction === 'RIGHT' && currentCardIndex > 0) {
    setCurrentCardIndex(currentCardIndex - 1);
  }
};

const useSwipeHandlers = ({
  currentCardIndex,
  eventCount,
  setCurrentCardIndex
}) =>
  useSwipeable({
    onSwipedLeft: () =>
      handleSwipe({
        currentCardIndex,
        direction: 'LEFT',
        eventCount,
        setCurrentCardIndex
      }),
    onSwipedRight: () =>
      handleSwipe({
        currentCardIndex,
        direction: 'RIGHT',
        eventCount,
        setCurrentCardIndex
      }),
    trackMouse: true
  });

export default useSwipeHandlers;
