import { PropTypes } from 'prop-types';

const TextKeyValuePair = ({
  label,
  labelClass = 'text-silver uppercase text-xs md:text-sm',
  labelId = '',
  value,
  valueClassName = 'text-base md:text-lg',
  valueId = '',
  className = 'flex-col',
  spaceTop = 'mt-2 md:mt-0'
}) => (
  <div className={`flex gap-2 ${className} ${spaceTop}`}>
    <div
      className={labelClass}
      id={labelId}
    >
      {label}
    </div>
    <div
      className={valueClassName}
      id={valueId}
    >
      {value}
    </div>
  </div>
);

export default TextKeyValuePair;

TextKeyValuePair.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
