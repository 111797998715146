import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  CartItemCTA,
  CartItemDetailCTA,
  CartItemNotes,
  RackRateOfOrderedCartItem,
  RackRateOfUnOrderedCartItem,
  UnSavedChangesErrorMessage
} from '@/components/atomic/atoms';
import { Dropdown, InputField, Text } from '@/components/atomic/nuclei';
import { VIEW_EDIT_MODE } from '@/config/cart';
import { CART_ITEM_STATUS, PRODUCT_TYPE } from '@/config/common';
import useYupValidator from '@/hooks/useYupValidator';
import { getSerializedRichText } from '@/lib/editor';
import {
  parseFormatPercentValueFromAPI,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import {
  onProductPartnerSelectionChange,
  onQuantityChange,
  onUnitListedPriceChange,
  onUnitPartnerPriceChange,
  trimPercentSign
} from '@/services/cart.plan.service';

const CartItemDetail = ({
  cartItem,
  cartItemRef,
  cartItemViewEditMode,
  isCartEditable,
  isReadOnly,
  productPartnersDropdownList,
  setCartItemIdInViewEditMode,
  setCartItemViewEditMode,
  unSavedChangesErrorMessage,
  updateCartItemDetail
}) => {
  const {
    id,
    product,
    partnerId,
    priceNotes,
    productNotes,
    quantity,
    status,
    derivedValues
  } = cartItem;
  const {
    haflaMarginPercent,
    listedDiscountPercentage,
    rackDiscountPercentage,
    sellingPrice,
    unitListedDiscount,
    unitListedPrice,
    unitPartnerPrice,
    unitRackPrice,
    unitSellingPrice
  } = derivedValues;
  const {
    id: productId,
    name,
    productPartners,
    type: productType
  } = product || {};

  const isCartItemInViewMode = cartItemViewEditMode === VIEW_EDIT_MODE.VIEW;
  const isCartTypeProduct = productType === PRODUCT_TYPE.CART;

  const [isUnitListedPriceNotEditable, setIsUnitListedPriceNotEditable] =
    useState(Boolean(!priceNotes) && isCartTypeProduct);
  const [updatePartnerSelectionFlag, setUpdatePartnerSelectionFlag] =
    useState(false);

  const validateUnitListedPrice = (currentUnitListedPriceValue) => {
    const isDeviationPresentInUnitListedPrice =
      Number(currentUnitListedPriceValue) !==
      Number(parseFormatPriceValueFromAPI(unitListedPrice));
    const isDeviationPresentInRackPrice =
      Number(currentUnitListedPriceValue) !==
      Number(parseFormatPriceValueFromAPI(unitRackPrice));
    return unitListedDiscount
      ? isDeviationPresentInUnitListedPrice
      : isDeviationPresentInRackPrice;
  };

  const yupResolver = useYupValidator(
    yup.object().shape({
      quantity: yup
        .number()
        .typeError('Quantity must be a number')
        .min(1, 'Minimum quantity should be > 0'),
      unitListedPrice: yup
        .number()
        .typeError('Amount must be a number')
        .min(1, 'Minimum amount should be > 0'),
      priceNotes: yup
        .array()
        .test(
          'priceNoteValidation',
          'Price notes are mandatory when Unit List Price is changed.',
          (value, { parent }) =>
            Boolean(getSerializedRichText(value)) ||
            !(
              validateUnitListedPrice(parent.unitListedPrice) &&
              isCartTypeProduct
            )
        )
        .nullable()
    })
  );

  const initialCartItemDetail = {
    unitSellingPrice: parseFormatPriceValueFromAPI(unitSellingPrice),
    id,
    partnerId,
    haflaMarginPercent: `${parseFormatPercentValueFromAPI(
      haflaMarginPercent
    )} %`,
    partnerPrice: parseFormatPriceValueFromAPI(unitPartnerPrice * quantity),
    priceNotes,
    productNotes,
    quantity,
    status: status.toUpperCase(),
    unitListedDiscount: parseFormatPriceValueFromAPI(unitListedDiscount),
    unitListedPrice: parseFormatPriceValueFromAPI(unitListedPrice),
    unitPartnerPrice: parseFormatPriceValueFromAPI(unitPartnerPrice),
    unitRackPrice: parseFormatPriceValueFromAPI(
      listedDiscountPercentage ? unitSellingPrice : unitRackPrice
    ),
    sellingPrice: parseFormatPriceValueFromAPI(sellingPrice)
  };

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver,
    defaultValues: initialCartItemDetail
  });

  useEffect(() => {}, [updatePartnerSelectionFlag]);

  const discardChanges = () => {
    reset();
    if (isCartItemInViewMode) {
      setCartItemIdInViewEditMode(null);
    } else {
      setCartItemViewEditMode(VIEW_EDIT_MODE.VIEW);
    }
  };

  const CartItemRackRate =
    status === CART_ITEM_STATUS.ORDERED.value
      ? RackRateOfOrderedCartItem
      : RackRateOfUnOrderedCartItem;

  return (
    <>
      <div
        className='flex flex-col gap-6 py-5 px-6 border-l border-r border-b bg-planner-cart-head'
        ref={cartItemRef}
      >
        <div className='flex justify-between gap-5'>
          <div className='flex gap-3'>
            <CartItemCTA
              {...{
                additionalProps: {
                  productId
                },
                type: 'viewInOps'
              }}
            />
            <Text {...{ className: 'text-lg font-medium', content: name }} />
          </div>
          {!isReadOnly && (
            <div className={unSavedChangesErrorMessage ? 'flex-1' : ''}>
              <div className='flex justify-between gap-4'>
                <UnSavedChangesErrorMessage
                  {...{ unSavedChangesErrorMessage }}
                />
                <CartItemDetailCTA
                  {...{
                    discardChanges,
                    handleSubmit,
                    isCartEditable,
                    isCartItemInViewMode,
                    setCartItemIdInViewEditMode,
                    setCartItemViewEditMode,
                    unSavedChangesErrorMessage,
                    updateCartItemDetail
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className='flex gap-20'>
          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'quantity',
                disabled: isCartItemInViewMode,
                errors,
                inputGroup: 'flex',
                label: 'Quantity',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: register('quantity', {
                  onChange: (e) =>
                    onQuantityChange({
                      getValues,
                      inputtedQuantity: e.target.value,
                      setValue
                    })
                }),
                type: 'number'
              }}
            />
          </div>
          <div className='w-1/2 flex gap-20'>
            <Text
              {...{
                className: 'self-center text-sm font-medium',
                content: 'Web Price'
              }}
            />
            <div className='flex gap-2 ml-2 bg-zinc w-full p-2 flex-1 bg-ghost-white border rounded-lg'>
              <CartItemRackRate
                {...{
                  product,
                  rackDiscountPercentage,
                  type: productType,
                  unitListedDiscount,
                  unitListedPrice,
                  unitRackPrice
                }}
              />
            </div>
          </div>
        </div>
        <div className='flex gap-20'>
          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'unitListedPrice',
                disabled: isCartItemInViewMode || isUnitListedPriceNotEditable,
                errors,
                inputGroup: 'flex',
                label: 'Unit List Price',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: {
                  ...register('unitListedPrice', {
                    onChange: (e) =>
                      onUnitListedPriceChange({
                        inputtedUnitListedPrice: e.target.value,
                        getValues,
                        setValue
                      })
                  })
                },
                type: 'number'
              }}
            />
          </div>

          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'unitListedDiscount',
                disabled: true,
                inputGroup: 'flex',
                label: 'Unit List Discount',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: register('unitListedDiscount')
              }}
            />
          </div>
        </div>
        <div className='flex gap-20'>
          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'unitSellingPrice',
                disabled: true,
                inputGroup: 'flex',
                label: 'Unit Selling Price',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: register('unitSellingPrice')
              }}
            />
          </div>
          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'sellingPrice',
                disabled: true,
                inputGroup: 'flex',
                label: 'Item Total',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: register('sellingPrice')
              }}
            />
          </div>
        </div>
        <div className='flex gap-20'>
          <div className='w-1/2 flex'>
            <Text
              {...{
                className: 'text-sm font-medium self-center flex w-52',
                content: 'Partner'
              }}
            />
            <Dropdown
              {...{
                clearErrors,
                control,
                dbName: 'partnerId',
                errors,
                getValues,
                isDisabled: isCartItemInViewMode,
                isSearchable: true,
                name: 'PartnerName',
                onChange: (e) =>
                  onProductPartnerSelectionChange({
                    clearErrors,
                    getValues,
                    partnerId: e.value,
                    productPartners,
                    setUpdatePartnerSelectionFlag,
                    setValue,
                    updatePartnerSelectionFlag
                  }),
                options: productPartnersDropdownList,
                placeholder: 'Select any partner',
                register,
                setValue
              }}
            />
          </div>
          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'unitPartnerPrice',
                inputGroup: 'flex',
                label: 'Unit Partner Price',
                labelClass: 'text-sm font-medium self-center flex w-40',
                disabled: isCartItemInViewMode,
                register: {
                  ...register('unitPartnerPrice', {
                    onChange: (e) =>
                      onUnitPartnerPriceChange({
                        getValues,
                        inputtedUnitPartnerPrice: e.target.value,
                        setValue
                      })
                  })
                },
                type: 'number'
              }}
            />
          </div>
        </div>
        <div className='flex gap-20'>
          <div className='w-1/2'>
            <InputField
              {...{
                className: 'rounded-lg flex-1',
                dbName: 'partnerPrice',
                disabled: true,
                inputGroup: 'flex',
                label: 'Total Partner Price',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: register('partnerPrice')
              }}
            />
          </div>
          <div className='w-1/2'>
            <InputField
              {...{
                className: `rounded-lg flex-1 ${
                  Number(trimPercentSign(getValues('haflaMarginPercent'))) >= 0
                    ? 'text-green'
                    : 'text-red-500'
                }`,
                dbName: 'haflaMarginPercent',
                disabled: true,
                inputGroup: 'flex',
                label: 'Margin %',
                labelClass: 'text-sm font-medium self-center flex w-40',
                register: register('haflaMarginPercent')
              }}
            />
          </div>
        </div>
        <CartItemNotes
          {...{
            errors,
            isCartItemInViewMode,
            priceNotes,
            productNotes,
            setIsUnitListedPriceNotEditable,
            setValue,
            status
          }}
        />
      </div>
    </>
  );
};

export default CartItemDetail;
