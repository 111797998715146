import Link from 'next/link';

import {
  TextKeyValuePair,
  TextWithIcon,
  Tooltip
} from '@/components/atomic/nuclei';
import { convertToStandardMobileNumberFormat } from '@/lib/mobileNumberValidation';
import { getFormattedDate } from '@/lib/time';
import { parseNumberedLabel } from '@/lib/utils';
import { getPageURL, PAGE_NAME } from '@/services/plannerPortal.service';

const ActionCenterUserEventCardMobile = ({
  hit: {
    entityId,
    eventCity,
    eventDate,
    hostCreditEntityName,
    hostMobile,
    userEventName,
    userEventNumber,
    zendeskTicketNumber
  }
}) => {
  const tooltipConfigList = [
    {
      target: `event-date-${entityId}`,
      text: 'Event Date'
    },
    {
      target: `event-title-${entityId}`,
      text: 'Event Title'
    },

    {
      target: `host-mobile-number-${entityId}`,
      text: 'Host Mobile Number'
    },
    {
      target: `event-city-${entityId}`,
      text: 'Event Location(City)'
    },

    {
      target: `event-number-${entityId}`,
      text: 'Event Number'
    }
  ];

  const userEventCardDetails = [
    {
      icon: 'call-with-frame.svg',
      id: `host-mobile-number-${entityId}`,
      label: convertToStandardMobileNumberFormat(hostMobile)
    },
    {
      icon: 'location-icon-with-frame.svg',
      id: `event-city-${entityId}`,
      label: eventCity
    }
  ];

  return (
    <Link
      href={getPageURL({
        pageName: PAGE_NAME.EVENT_CART_LIST.label,
        pathParams: { userEventId: entityId }
      })}
      target='_blank'
    >
      <div className='flex flex-col border border-neutral hover:border-brand shadow-navigate-cart rounded-lg pl-2'>
        <div className='flex justify-between'>
          <TextWithIcon
            {...{
              alt: 'zendesk',
              className: 'shadow-none py-1',
              gap: 'gap-[3px]',
              icon: 'zendesk-with-frame.svg',
              iconHeight: 12,
              iconWidth: 12,
              label: parseNumberedLabel(zendeskTicketNumber),
              labelStyle: 'text-[10px] font-normal text-nero'
            }}
          />
          <TextWithIcon
            {...{
              alt: 'user event white icon',
              className:
                'shadow-none bg-brand-gradient py-1 px-2 rounded-bl-lg rounded-tr-lg',
              gap: 'gap-[4px]',
              icon: 'user-event-white-icon.svg',
              iconHeight: 12,
              iconWidth: 12,
              id: `event-number-${entityId}`,
              label: parseNumberedLabel(userEventNumber),
              labelStyle: 'text-[10px] font-medium text-white leading-3'
            }}
          />
        </div>
        <TextKeyValuePair
          {...{
            className: 'flex gap-2 items-center',
            label: getFormattedDate({ date: eventDate, format: 'Do MMM' }),
            labelClass:
              'bg-brand-gradient text-white font-semibold min-w-10 max-w-10 h-10 rounded flex text-center items-center mb-1 p-1 text-xs',
            labelId: `event-date-${entityId}`,
            spaceTop: '',
            value: userEventName,
            valueClassName: 'font-medium text-xs',
            valueId: `event-title-${entityId}`
          }}
        />
        <div className='pb-2'>
          <div className='flex flex-col gap-0'>
            <div className='flex justify-between pr-2 gap-2'>
              {userEventCardDetails.map((userEventCardDetail, index) => (
                <TextWithIcon
                  key={`${userEventCardDetail.id}_${index}`}
                  {...{
                    ...userEventCardDetail,
                    className: 'shadow-none',
                    gap: 'gap-[5px]',
                    iconHeight: 12,
                    iconWidth: 12,
                    labelStyle: 'text-[10px] font-normal text-nero'
                  }}
                />
              ))}
            </div>
          </div>
          <TextWithIcon
            {...{
              alt: 'host credit entity icon',
              className: 'shadow-none',
              icon: 'host-credit-entity-red-icon.svg',
              iconHeight: 12,
              iconWidth: 12,
              label: hostCreditEntityName,
              labelStyle: 'text-[10px] font-normal text-nero'
            }}
          />
        </div>
      </div>
      <Tooltip {...{ configList: tooltipConfigList }} />
    </Link>
  );
};

export default ActionCenterUserEventCardMobile;
