import Image from 'next/image';
import { PropTypes } from 'prop-types';

import { Text } from '@/components/atomic/nuclei';
import {
  DEFAULT_TOAST_CONFIG,
  staticMediaStoreBaseURL
} from '@/config/common';

const Icon = ({ iconSrc }) => (
  <div className='self-center flex'>
    <Image
      alt='ToasterIcon'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/${iconSrc}`}
      style={{ height: 16, width: 16 }}
      width={0}
    />
  </div>
);

const Toaster = ({ iconSrc, message, setMessage, showIcon }) => {
  setTimeout(() => setMessage(DEFAULT_TOAST_CONFIG), 5000);
  return (
    <div
      className={`bg-black text-white text-sm font-medium flex gap-3 justify-center py-3
      rounded-lg fixed px-6 opacity-80 bottom-10 left-1/2 w-4/5 md:w-auto toaster z-1200`}
    >
      {showIcon && <Icon {...{ iconSrc }} />}
      <Text
        className='self-center'
        content={message}
      />
    </div>
  );
};

export default Toaster;

Toaster.propTypes = {
  iconSrc: PropTypes.string,
  message: PropTypes.string,
  setMessage: PropTypes.func,
  showIcon: PropTypes.bool
};
