// TODO: Tanmay - this client can be removed once we enable role-determination from x-access-token in absence of cookie. we will wait for few weeks to see if there are other use cases to have this custom client. [known 1) cookies 2) custom result response]

function isSearchkit(config) {
  return config.handleInstantSearchRequests !== undefined;
}

class InstantSearchElasticsearchAdapter {
  constructor(config, requestOptions) {
    this.config = config;
    this.requestOptions = requestOptions;
    this.cache = [];
    if (!isSearchkit(this.config) && !this.config.url) {
      throw new Error('Searchkit Instantsearch Client: url is required');
    }
    if (!isSearchkit(this.config) && this.requestOptions) {
      throw new Error(
        'Searchkit Instantsearch Client: requestOptions is not supported when used with url. Add the request options to @searchkit/api instead.'
      );
    }
  }

  clearCache() {
    this.cache = [];
    return Promise.resolve(undefined);
  }

  getHeaders() {
    let headers = {};
    if (!isSearchkit(this.config) && this.config.headers) {
      headers =
        typeof this.config.headers === 'function'
          ? this.config.headers()
          : this.config.headers;
    }
    return headers;
  }

  async search(instantsearchRequests) {
    try {
      const key = JSON.stringify(instantsearchRequests);
      const cacheValue = this.cache[key];
      if (cacheValue) {
        return cacheValue;
      }

      if (isSearchkit(this.config)) {
        const results = await this.config.handleInstantSearchRequests(
          instantsearchRequests,
          this.requestOptions
        );
        this.cache[key] = results;
        return results;
      }

      const response = await fetch(this.config.url, {
        body: JSON.stringify(instantsearchRequests),
        headers: {
          'Content-Type': 'application/json',
          ...this.getHeaders()
        },
        credentials: 'include',
        method: 'POST'
      });

      const results = await response.json();
      this.cache[key] = results;
      return results;
    } catch {
      return [];
    }
  }

  async searchForFacetValues(instantsearchRequests) {
    const isr = instantsearchRequests.map((request) => ({
      ...request,
      params: {
        ...request.params,
        hitsPerPage: 0
      }
    }));

    try {
      if (isSearchkit(this.config)) {
        const results = await this.config.handleInstantSearchRequests(isr);
        return results.results;
      }

      const response = await fetch(this.config.url, {
        body: JSON.stringify(isr),
        headers: {
          'Content-Type': 'application/json',
          ...this.getHeaders()
        },
        credentials: 'include',
        method: 'POST'
      });

      const results = await response.json();
      return results.results;
    } catch {
      return [];
    }
  }
}

const createClient = (config, requestOptions) =>
  new InstantSearchElasticsearchAdapter(config, requestOptions);

export default createClient;
