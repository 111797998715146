import Image from 'next/image';

import { Text, TextKeyValuePair } from '@/components/atomic/nuclei';
import {
  haflaPublicSupportMobileNumber,
  staticMediaStoreBaseURL
} from '@/config/common';

const EmptyCart = () => (
  <>
    <Text
      {...{
        content: 'Your Cart is Empty!',
        className: 'text-xl font-medium text-basic-black mt-10'
      }}
    />
    <Text
      {...{
        content: 'Click below to explore and add',
        className: 'text-base mt-2 text-basic-black'
      }}
    />
    <Text
      {...{
        content: 'products to the cart for your event!',
        className: 'text-base text-basic-black'
      }}
    />
  </>
);

// TODO: Faizan why will hafla planner contact hafla support - pair with HT+DC and then use apt string
const CartNotFound = () => (
  <TextKeyValuePair
    {...{
      className: '',
      label: 'Oh no! The cart you are looking for does not exist.',
      labelClass: 'text-xl font-bold mt-10',
      value: `Reach out to our event expert on ${haflaPublicSupportMobileNumber} for more details.`,
      valueClassName: 'text-sm text-silver mt-3'
    }}
  />
);

const EmptyCartBody = ({ cartFound }) => {
  const imageSrc = `${staticMediaStoreBaseURL}/cart/${
    cartFound ? 'no-product-on-cart.svg' : 'empty-cart.svg'
  }`;
  return (
    <div className='flex flex-col items-center mx-auto mt-20'>
      <Image
        alt={`${cartFound ? 'empty cart' : 'cart not found'}`}
        className='cursor-pointer'
        height={0}
        priority={true}
        src={imageSrc}
        style={{ height: cartFound ? 240 : 200, width: cartFound ? 366 : 275 }}
        width={0}
      />
      {cartFound ? <EmptyCart /> : <CartNotFound />}
    </div>
  );
};

export default EmptyCartBody;
