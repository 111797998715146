import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import {
  Button,
  CloseIcon,
  InputField,
  SelectFormInput,
  Text,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { REPAYMENT_TERM_DAYS_LIMIT } from '@/config/hostCreditEntity';
import useYupValidator from '@/hooks/useYupValidator';
import {
  convertToCurrencyFormat,
  parseFormatPriceNumberValueFromAPI,
  parsePriceValueForAPI
} from '@/lib/numberStringUtils';
import {
  createHostCreditEntityCreditRequest,
  fetchCreditRequestReasons
} from '@/services/hostCreditEntity.service';

const RequestCreditModal = ({
  creditFacilityInformation: { outstandingPayment },
  hostCreditEntityId,
  requestCreditModalToggle,
  setHostCreditEntityCreditRequests,
  setShowToastContainer
}) => {
  const [creditRequestReasons, setCreditRequestReasons] = useState([]);

  useEffect(() => {
    fetchCreditRequestReasons({
      setCreditRequestReasons,
      setShowToast: setShowToastContainer
    });
  }, []);

  const yupResolver = useYupValidator(
    yup.object().shape({
      creditRequestReasonId: yup
        .string()
        .required('Reason for credit is required.'),
      repaymentTermDays: yup
        .number()
        .typeError('Payment period must be a number')
        .required('Payment period is required')
        .min(
          REPAYMENT_TERM_DAYS_LIMIT.MIN,
          `Payment period must be ${REPAYMENT_TERM_DAYS_LIMIT.MIN} to ${REPAYMENT_TERM_DAYS_LIMIT.MAX} days`
        )
        .max(
          REPAYMENT_TERM_DAYS_LIMIT.MAX,
          `Payment period must be ${REPAYMENT_TERM_DAYS_LIMIT.MIN} to ${REPAYMENT_TERM_DAYS_LIMIT.MAX} days`
        ),
      requestedCreditLimit: yup
        .number()
        .typeError('Credit request amount must be a number')
        .positive('Credit request amount should be > 0')
        .required('Credit request amount is required.')
    })
  );

  const {
    clearErrors,
    control,
    getValues,
    handleSubmit,
    register,
    setValue,
    trigger,
    watch
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver
  });

  const { errors, isValid } = useFormState({
    control
  });

  const onSubmit = (dataToSubmit) => {
    const payloadData = {
      ...dataToSubmit,
      requestedCreditLimit: parsePriceValueForAPI(
        dataToSubmit.requestedCreditLimit
      )
    };
    createHostCreditEntityCreditRequest({
      dataToSubmit: payloadData,
      hostCreditEntityId,
      setHostCreditEntityCreditRequests,
      setShowToastContainer
    });
  };

  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={true}
    >
      <ModalBody>
        <div className='flex h-screen justify-center'>
          <div className='self-center relative bg-white px-6 rounded-lg w-[720px]'>
            <div className='flex justify-end pt-4 mb-4 relative left-3 -top-1'>
              <CloseIcon {...{ onClick: requestCreditModalToggle }} />
            </div>
            <Text
              {...{
                className: 'text-xl font-medium text-nero mb-3 text-center',
                content: 'Apply For Credit'
              }}
            />
            <div className='flex flex-col gap-4 mt-4'>
              <InputField
                {...{
                  className: 'rounded-lg pl-10',
                  dbName: 'requestedCreditLimit',
                  errors,
                  iconURL: 'budget-icon-red.svg',
                  inputGroup: 'flex flex-col gap-2 w-full',
                  label: 'Credit Request Amount *',
                  labelClass: 'text-nero text-base font-medium',
                  onKeyDown: (e) => {
                    if (e.key === '.' || e.key === '-' || e.key === 'e') {
                      e.preventDefault();
                    }
                  },
                  placeholder: 'Enter Credit Amount',
                  register: { ...register('requestedCreditLimit') },
                  type: 'number'
                }}
              />
              <div className='flex justify-between -mt-2'>
                <TextKeyValuePair
                  {...{
                    className: 'flex-row !gap-1 !mt-0',
                    label: 'Outstanding Payment:',
                    labelClass: 'text-xs text-dim-gray font-medium',
                    value: convertToCurrencyFormat({
                      value:
                        parseFormatPriceNumberValueFromAPI(outstandingPayment)
                    }),
                    valueClassName: 'text-xs text-dim-gray font-medium'
                  }}
                />
                <div className='flex gap-2'>
                  <TextKeyValuePair
                    {...{
                      className: 'flex-row !gap-1 !mt-0',
                      label: 'Net Request Amount:',
                      labelClass: 'text-xs text-green font-medium',
                      value: convertToCurrencyFormat({
                        value:
                          Number(watch('requestedCreditLimit')) -
                          parseFormatPriceNumberValueFromAPI(
                            outstandingPayment
                          )
                      }),
                      valueClassName: 'text-xs text-green font-medium'
                    }}
                  />
                  <Text
                    {...{
                      className: 'text-xs font-light text-dim-gray',
                      content: `[${Number(
                        watch('requestedCreditLimit')
                      )} - ${parseFormatPriceNumberValueFromAPI(
                        outstandingPayment
                      )}]`
                    }}
                  />
                </div>
              </div>
              <InputField
                {...{
                  className: 'rounded-lg pl-10',
                  dbName: 'repaymentTermDays',
                  errors,
                  iconURL: 'trn-icon-red.svg',
                  inputGroup: 'flex flex-col gap-2 w-full',
                  label: 'Payment Terms (in days) *',
                  labelClass: 'text-nero text-base font-medium',
                  maxLength: 3,
                  placeholder: `${REPAYMENT_TERM_DAYS_LIMIT.MIN} - ${REPAYMENT_TERM_DAYS_LIMIT.MAX} days (payback terms)`,
                  register: { ...register('repaymentTermDays') },
                  type: 'number'
                }}
              />
              <SelectFormInput
                {...{
                  clearErrors,
                  containerClassName:
                    'relative multi-select-epm epm-dropdown border border-platinum rounded',
                  dbName: 'creditRequestReasonId',
                  errors,
                  getValues,
                  icon: {
                    url: 'trn-icon-red.svg'
                  },
                  isClearable: false,
                  label: 'Reason for Credit *',
                  name: 'credit reason',
                  placeholderLabel: 'Reason for Credit',
                  register,
                  setValue,
                  showIcon: true,
                  showLabel: true,
                  trigger,
                  values: creditRequestReasons
                }}
              />
              {
                // TODO: HP/FV - remove class 'hidden' when implement to show credit reason notes when select 'Other' as Credit Reason
              }
              <div className='hidden'>
                <InputField
                  {...{
                    className: 'rounded-lg pl-3',
                    dbName: 'credit',
                    inputGroup: 'flex flex-col gap-2 w-full',
                    labelClass: 'text-nero text-base font-medium',
                    maxLength: 6,
                    placeholder: 'Type reason for credit request',
                    register: () => {}
                  }}
                />
              </div>
            </div>
            <Button
              {...{
                className:
                  'bg-brand-gradient text-white text-sm font-medium py-3 rounded mt-4 mb-4',
                disabled: !isValid,
                label: 'Apply',
                onClick: handleSubmit(onSubmit)
              }}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RequestCreditModal;
